export const FigmaLogo = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 300"
        width={width ?? "16"}
        height={height ?? "24"}
      >
        <path
          fill="#0acf83"
          d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
        />
        <path
          fill="#a259ff"
          d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
        />
        <path
          fill="#f24e1e"
          d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
        />
        <path
          fill="#ff7262"
          d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
        />
        <path
          fill="#1abcfe"
          d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
        />
      </svg>
    </>
  );
};

export const CanvaLogo = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1900 1900"
        width={width ?? "36"}
        height={height ?? "36"}
        fill="none"
      >
        <circle cx="950" cy="950" r="950" fill="#7D2AE7" />
        <circle cx="950" cy="950" r="950" fill="url(#a)" />
        <circle cx="950" cy="950" r="950" fill="url(#b)" />
        <circle cx="950" cy="950" r="950" fill="url(#c)" />
        <circle cx="950" cy="950" r="950" fill="url(#d)" />
        <path
          fill="#fff"
          d="M1360.14 1144.88c-7.84 0-14.74 6.62-21.92 21.08-81.08 164.41-221.12 280.74-383.174 280.74-187.38 0-303.419-169.15-303.419-402.83 0-395.834 220.548-624.697 414.263-624.697 90.52 0 145.8 56.885 145.8 147.41 0 107.439-61.04 164.324-61.04 202.216 0 17.009 10.58 27.308 31.56 27.308 84.29 0 183.22-96.855 183.22-233.682 0-132.669-115.47-230.186-309.18-230.186-320.145 0-604.665 296.804-604.665 707.471 0 317.88 181.522 527.94 461.601 527.94 297.274 0 469.164-295.77 469.164-391.77 0-21.26-10.87-31-22.21-31Z"
        />
        <defs>
          <radialGradient
            id="a"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(-49.416 2013.48303023 443.1854085) scale(1469.49)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6420FF" />
            <stop offset="1" stopColor="#6420FF" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="b"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="rotate(54.703 42.71673666 594.1943586) scale(1657.12)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C4CC" />
            <stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="c"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(1023.00018 -1030.00171 473.71139 470.4913 367 1684)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6420FF" />
            <stop offset="1" stopColor="#6420FF" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="d"
            cx="0"
            cy="0"
            r="1"
            gradientTransform="matrix(595.99869 1371.99953 -2298.40601 998.43108 777 256)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C4CC" stopOpacity=".725916" />
            <stop offset=".0001" stopColor="#00C4CC" />
            <stop offset="1" stopColor="#00C4CC" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </>
  );
};
export const CanvaLogo2 = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <>
      <svg
        width={width ?? "36"}
        height={height ?? "36"}
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5548 11.6297C11.4565 11.6297 11.37 11.719 11.28 11.9141C10.2637 14.1321 8.5084 15.7015 6.47713 15.7015C4.12842 15.7015 2.67393 13.4195 2.67393 10.267C2.67393 4.92685 5.43839 1.83931 7.86651 1.83931C9.00113 1.83931 9.69403 2.60674 9.69403 3.82799C9.69403 5.27743 8.92893 6.04485 8.92893 6.55604C8.92893 6.78551 9.06154 6.92445 9.32452 6.92445C10.381 6.92445 11.6211 5.6178 11.6211 3.77189C11.6211 1.98208 10.1737 0.666504 7.74567 0.666504C3.73282 0.666504 0.166504 4.67062 0.166504 10.2108C0.166504 14.4993 2.44179 17.3332 5.95244 17.3332C9.67862 17.3332 11.8332 13.343 11.8332 12.0479C11.8332 11.7611 11.6969 11.6297 11.5548 11.6297Z"
          fill="#F8FAFC"
        />
      </svg>
    </>
  );
};

export const PSLogo = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_836_5656)">
        <path
          d="M1.77083 0H8.22917C9.20833 0 10 0.811966 10 1.81624V8.18376C10 9.18803 9.20833 10 8.22917 10H1.77083C0.791667 10 0 9.18803 0 8.18376V1.81624C0 0.811966 0.791667 0 1.77083 0Z"
          fill="#001E36"
        />
        <path
          d="M2.25 7.01281V2.61537C2.25 2.58546 2.2625 2.56837 2.29167 2.56837C2.3625 2.56837 2.42917 2.56837 2.525 2.56409C2.625 2.55982 2.72917 2.55982 2.84167 2.55555C2.95417 2.55127 3.075 2.55127 3.20417 2.547C3.33333 2.54272 3.45833 2.54272 3.58333 2.54272C3.925 2.54272 4.20833 2.58546 4.44167 2.6752C4.65 2.74785 4.84167 2.86751 5 3.02563C5.13333 3.16238 5.2375 3.32905 5.30417 3.51281C5.36667 3.6923 5.4 3.87606 5.4 4.06837C5.4 4.43589 5.31667 4.73931 5.15 4.97862C4.98333 5.21794 4.75 5.39743 4.47917 5.49999C4.19583 5.60683 3.88333 5.64529 3.54167 5.64529C3.44167 5.64529 3.375 5.64529 3.33333 5.64102C3.29167 5.63674 3.23333 5.63674 3.15417 5.63674V7.00854C3.15833 7.03845 3.1375 7.06409 3.10833 7.06837C3.10417 7.06837 3.1 7.06837 3.09167 7.06837H2.3C2.26667 7.06837 2.25 7.05127 2.25 7.01281ZM3.15833 3.39315V4.82905C3.21667 4.83332 3.27083 4.8376 3.32083 4.8376H3.54167C3.70417 4.8376 3.86667 4.81196 4.02083 4.76067C4.15417 4.72221 4.27083 4.64102 4.3625 4.53418C4.45 4.42734 4.49167 4.28204 4.49167 4.09401C4.49583 3.96153 4.4625 3.82905 4.39583 3.71366C4.325 3.60255 4.225 3.51708 4.10417 3.47007C3.95 3.40597 3.78333 3.38033 3.6125 3.3846C3.50417 3.3846 3.40833 3.38461 3.32917 3.38888C3.24583 3.38461 3.1875 3.38888 3.15833 3.39315Z"
          fill="#31A8FF"
        />
        <path
          d="M7.99991 4.56835C7.87491 4.49997 7.74158 4.45297 7.59991 4.42305C7.44575 4.38886 7.29158 4.3675 7.13325 4.3675C7.04991 4.36322 6.96241 4.37604 6.88325 4.39741C6.82908 4.41023 6.78325 4.44015 6.75408 4.48288C6.73325 4.51707 6.72075 4.5598 6.72075 4.59827C6.72075 4.63673 6.73741 4.67519 6.76241 4.70938C6.79991 4.75638 6.84991 4.79485 6.90408 4.82476C6.99991 4.87604 7.09991 4.92305 7.19991 4.96579C7.42491 5.04271 7.64158 5.14955 7.84158 5.27775C7.97908 5.3675 8.09158 5.48715 8.17075 5.63245C8.23741 5.76921 8.27075 5.91878 8.26658 6.07262C8.27075 6.27348 8.21241 6.47433 8.10408 6.641C7.98741 6.81194 7.82491 6.94442 7.63741 7.02134C7.43325 7.11109 7.18325 7.15809 6.88325 7.15809C6.69158 7.15809 6.50408 7.141 6.31658 7.10254C6.17075 7.0769 6.02491 7.02989 5.89158 6.96579C5.86241 6.94869 5.84158 6.91878 5.84575 6.88459V6.141C5.84575 6.12818 5.84991 6.11109 5.86241 6.10254C5.87491 6.09399 5.88741 6.09826 5.89991 6.10681C6.06241 6.2051 6.23325 6.27348 6.41658 6.31621C6.57491 6.35895 6.74158 6.38032 6.90825 6.38032C7.06658 6.38032 7.17908 6.35895 7.25408 6.32049C7.32075 6.29057 7.36658 6.21792 7.36658 6.141C7.36658 6.08117 7.33325 6.02562 7.26658 5.97006C7.19991 5.9145 7.06241 5.8504 6.85825 5.76921C6.64575 5.69228 6.44991 5.58972 6.26658 5.46151C6.13741 5.3675 6.02908 5.24356 5.94991 5.09827C5.88325 4.96151 5.84991 4.81194 5.85408 4.66237C5.85408 4.47861 5.90408 4.30339 5.99575 4.14527C6.09991 3.97433 6.25408 3.83758 6.43325 3.75211C6.62908 3.64955 6.87491 3.60254 7.17075 3.60254C7.34158 3.60254 7.51658 3.61536 7.68741 3.641C7.81241 3.65809 7.93325 3.69228 8.04575 3.73929C8.06241 3.74356 8.07908 3.76066 8.08741 3.77775C8.09158 3.79485 8.09575 3.81194 8.09575 3.82903V4.52562C8.09575 4.54271 8.08741 4.5598 8.07491 4.56835C8.03741 4.5769 8.01658 4.5769 7.99991 4.56835Z"
          fill="#31A8FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_5656">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
