import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Ad } from '@/components/templates/DiscoverGridView';
import { ErrorDisplay } from '@/components/error.tsx';
import { trpc } from '@/utils/trpc.ts';
import { Loader } from '@/components/custom-components/Loader';
import InspirationTabs from '@/components/ad-inspiration/InspirationTabs.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import { toast } from 'sonner';
import { Link as LinkIcon } from '@mynaui/icons-react';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard.tsx';
import { ArrowLeftIcon } from 'lucide-react';
import {
  CollectionFilterSelect,
  GroupCollections,
} from '@/components/ad-inspiration/OrderFilterSelect.tsx';
import { AdCard, DiscoverAdCard } from '@/components/templates/AdCard';
import useCardLayoutWithImpressions from '@/hooks/useCardLayoutWithImpressions.tsx';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { featureFlagKeys } from '@/utils/data/featureFlags';
import { getRequestTabs, showTemplateRecentButton } from '@/utils/data/feeds';
import {
  MasonryComponent,
  TypedResponsiveMasonry,
} from '@/components/ResponsiveMasonryWrapper.tsx';

type SearchParams = {
  groupFilter?: GroupCollections;
  sideBarOpen?: boolean;
};

export const Route = createFileRoute('/feeds/requested/')({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const groupFilter = search?.groupFilter as GroupCollections;
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      groupFilter,
      sideBarOpen,
    };
  },
});

function All() {
  const { groupFilter } = Route.useSearch();

  const [, copyToClipboard] = useCopyToClipboard();

  const navigate = useNavigate();

  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.emailFeeds);

  const { squareRef, desiredCardWidth, columns, gutterWidth, addPostToBatch } =
    useCardLayoutWithImpressions();

  const {
    data: requestedTemplates,
    isLoading,
    isError,
  } = trpc.getAllRequestedTemplates.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <>
      <div className={''}>
        <div id={'app-header'} className={''}>
          <Button
            onClick={() =>
              navigate({
                to: '/feeds/collections',
              })
            }
            variant={'ghost'}
            className={'flex gap-1.5 items-center'}
          >
            <ArrowLeftIcon size={'1rem'} />
            <span>All Collections</span>
          </Button>
          <div className={'lg:flex justify-between gap-5 items-center mt-2'}>
            <div
              className={
                'flex justify-between lg:justify-start gap-5 items-center'
              }
            >
              <div className={''}>
                <h1 className={'text-2xl font-semibold'}>
                  Your Requests. Our Priority.
                </h1>
                <p
                  className={
                    'text-sm lg:text-base text-thememutedforeground mt-2'
                  }
                >
                  We craft every template by hand with you in mind.
                </p>
              </div>
            </div>
            <div className={'flex lg:flex-col gap-2 items-center mt-2 lg:mt-0'}>
              <Button
                onClick={() => {
                  copyToClipboard(`${window.location.origin}/feeds/requested`);
                  toast.success('Copied!');
                }}
                className={'hidden md:flex'}
                variant={'outline'}
                size={'sm'}
              >
                <LinkIcon /> Share
              </Button>
              <div>
                <span
                  className={
                    'text-thememutedforeground text-right text-sm lg:text-base'
                  }
                >
                  {requestedTemplates?.length || 0} items
                </span>
              </div>
            </div>
          </div>
          <div className={'mt-8'}>
            <div className={'flex gap-5 items-center justify-between'}>
              <div className={'flex gap-6 items-center'}>
                {
                  <InspirationTabs
                    pages={getRequestTabs({ showEmailFeeds: flagEnabled })}
                  />
                }
                <CollectionFilterSelect
                  defaultFilter={groupFilter || 'all'}
                  options={[
                    { label: 'All Requested', value: 'all' },
                    { label: 'Only Templates', value: 'only-templates' },
                  ]}
                  type={'requested'}
                />
              </div>
              <div>
                <Button
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/requested`,
                    );
                    toast.success('Copied!');
                  }}
                  className={'flex md:hidden'}
                  variant={'outline'}
                  size={'icon'}
                >
                  <LinkIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          {isLoading ? (
            <div className="flex justify-center items-center w-full h-screen">
              <Loader />
            </div>
          ) : (
            <div>
              {requestedTemplates && (
                <>
                  {(
                    groupFilter == 'all' || groupFilter === undefined
                      ? (requestedTemplates ?? []).length == 0
                      : (requestedTemplates ?? []).filter(
                          (data) => data.adTemplate,
                        ).length == 0
                  ) ? (
                    <div className={'lg:h-96 flex justify-center items-center'}>
                      <p>No template requests added to this collection</p>
                    </div>
                  ) : (
                    <div ref={squareRef} className={'relative w-full pb-10'}>
                      <div>
                        <TypedResponsiveMasonry
                          columnsCountBreakPoints={
                            columns ? { 0: columns } : {}
                          } // Columns is determined by the width of the container
                        >
                          <MasonryComponent gutter={gutterWidth / 16 + 'rem'}>
                            {(requestedTemplates ?? []).map((ad) => {
                              return (groupFilter === 'all' ||
                                groupFilter === undefined) &&
                                !ad.adTemplate ? (
                                <DiscoverAdCard
                                  key={ad.id}
                                  DesiredWidth={desiredCardWidth}
                                  adData={{ ...ad, isSaved: true } as Ad}
                                  IsPublic={false}
                                  IsLocked={false}
                                  IsRequested={true}
                                  onView={addPostToBatch}
                                />
                              ) : ad.adTemplate ? (
                                <AdCard
                                  key={ad.adTemplate?.atID}
                                  showTemplateButton
                                  showRecentButton={showTemplateRecentButton(
                                    ad.adTemplate?.Created,
                                  )}
                                  typeof=""
                                  adData={{
                                    Ad: { ...ad.adTemplate!, AdDbId: ad.id },
                                    IsLocked: false,
                                    IsPublic: false,
                                    brandName: undefined,
                                    shouldInvalidateCache: false,
                                    DesiredWidth: desiredCardWidth,
                                  }}
                                />
                              ) : (
                                <></>
                              );
                            })}
                          </MasonryComponent>
                        </TypedResponsiveMasonry>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
