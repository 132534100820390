import { MynaIconsProps, Pin, PinSolid, Plus } from "@mynaui/icons-react";
import { Link, useRouterState } from "@tanstack/react-router";
import { MouseEvent, useEffect, useState } from "react";
import { Stack } from "@/components/custom-components";
import { Button, buttonVariants } from "@/components/shadcn/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { PRO_PLAN } from "@/utils/data/plans.ts";
import { Badge } from "@/components/shadcn/badge.tsx";
import { ChevronDown, ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils.ts";
import { LockTagSmall } from "@/components/custom-components/LockTag";
import { Text } from "@/components/custom-components";
import { PingCirclesRed } from "./custom-components/PingCircles";

/**
 * Checks if the current pathname partially matches the target path up to a specified depth.
 *
 * @param pathname - The current path to be checked.
 * @param targetPath - The target path to match against.
 * @param depth - The number of segments to consider from both the current path and the target path.
 *                Defaults to 2. This means that only the first 'depth' segments of the paths will be compared.
 *                For example, with a depth of 2, '/feeds/boards/$boardID/ads' would be compared as '/feeds/boards'.
 *
 * @returns A boolean indicating whether the current path partially matches the target path up to the specified depth.
 * All this is being done so sub-links like /feeds/boards/$boardID/ads
 * and all other child pages can still be linked to the parent sub-link
 */
const isPartialPathMatch = ({
  pathname,
  targetPath,
  depth = 2,
}: {
  pathname?: string;
  targetPath?: string;
  depth?: number;
}) => {
  const pathSegments = pathname
    ?.split("/")
    .filter(Boolean)
    .slice(0, depth)
    .join("/");
  const targetSegments = targetPath
    ?.split("/")
    .filter(Boolean)
    .slice(0, depth)
    .join("/");

  return pathSegments?.startsWith(targetSegments ?? "");
};

interface SubItem {
  id?: string; // Optional unique identifier
  name: string; // Name of the sub-item (required)
  link?: string; // Optional URL link for navigation
  first2Links?: string; // Optional secondary link
  first3Links?: string; // Optional tertiary link
  isAddCollection?: boolean; // Flag to indicate if it's an "Add Collection" item
  ignoreProStatus?: boolean; // Flag to ignore Pro status restrictions
  icon?: JSX.Element; // Optional icon as a JSX element
  openCreateNewBoard?: () => void; // Optional function to create a new board
}

interface NavItem {
  link?: string;
  name: string;
  icon: (props: MynaIconsProps) => JSX.Element;
  exact?: boolean;
  showPingIcon?: boolean;
  subLinks?: Array<SubItem>;
  collapsibleSidebar?: boolean;
}

const CollectionItem = ({
  subItem,
  index,
  isMyCollections,
  handleSublinkClick,
  isLoading,
  userCanAccessEverything,
  isProFeature,
  setPinnedStates,
  pinnedStates,
}: {
  setPinnedStates: (pinnedStates: Record<string, boolean>) => void;
  subItem: {
    id?: string;
    name: string;
    link?: string;
    first2Links?: string;
    first3Links?: string;
    isAddCollection?: boolean;
    ignoreProStatus?: boolean;
    icon?: JSX.Element;
    openCreateNewBoard?: () => void;
  };
  pinnedStates: Record<string, boolean>;
  isMyCollections: boolean;
  index: number;
  isLoading: boolean;
  userCanAccessEverything?: boolean;
  isProFeature?: boolean;
  handleSublinkClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}) => {
  const router = useRouterState();
  const [showPin, setShowPin] = useState(false);

  return (
    <Link
      key={`middle-link-${index}`}
      onMouseEnter={() => setShowPin(true)}
      onMouseLeave={() => setShowPin(false)}
      onClick={
        (subItem.ignoreProStatus ?? false)
          ? undefined
          : !isMyCollections
            ? handleSublinkClick
            : subItem.openCreateNewBoard || undefined
      }
      to={subItem.link}
      search={() => ({
        sideBarOpen: false,
      })}
      className={
        (
          subItem.first2Links || subItem.first3Links
            ? isPartialPathMatch({
                pathname: location.pathname,
                targetPath: subItem.first2Links || subItem.first3Links,
                depth: subItem.first2Links ? 2 : 3,
              })
            : subItem.link && router.location.pathname.startsWith(subItem.link)
        )
          ? cn(
              buttonVariants({
                variant: "brandGradient",
                size: "sm",
                className: "!text-slate-50 gap-1.5 justify-between px-0 pl-2 w-full",
              })
            )
          : cn(
              buttonVariants({
                variant: "ghost",
                size: "sm",
                className: `text-slate-600 font-normal justify-between px-0 pl-2  w-full ${
                  !isLoading &&
                  !userCanAccessEverything &&
                  isProFeature &&
                  !(subItem.ignoreProStatus ?? false)
                    ? "opacity-50"
                    : ""
                }`,
              })
            )
      }
      activeOptions={{
        includeSearch: false,
        exact: true,
      }}
    >
      <div className="flex items-center gap-1.5 max-w-full overflow-hidden">
        <div className={`w-5 h-5 min-w-5`}>
          {subItem.link &&
            router?.location.pathname.startsWith(subItem.link) &&
            subItem?.icon}
        </div>
        <span
          className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
        >
          {subItem.name}
        </span>
      </div>
      {!isLoading &&
        !userCanAccessEverything &&
        isProFeature &&
        !(subItem.ignoreProStatus ?? false) && <div className="pr-2"><LockTagSmall /></div>}
      {subItem.isAddCollection && (
        <Plus className="w-5 h-5 hover:cursor-pointer" />
      )}
      {isLoading ||
      (!isLoading &&
        !userCanAccessEverything &&
        isProFeature &&
        !(subItem.ignoreProStatus ?? false)) ? (
        <></>
      ) : showPin ? (
        <Button
          variant={"ghost"}
          size={"iconSm"}
          className="hover:bg-themeinput"
          onClick={(e) => {
            e.preventDefault();
            setPinnedStates({
              ...pinnedStates,
              [subItem.id ?? subItem.name]: !(
                pinnedStates[subItem.id ?? subItem.name] ?? false
              ),
            });
          }}
        >
          {pinnedStates[subItem.id ?? subItem.name] ? (
            <PinSolid className="w-5 h-5 min-w-5 text-muted-foreground" />
          ) : (
            <Pin className="w-5 h-5 min-w-5 text-muted-foreground" />
          )}
        </Button>
      ) : pinnedStates[subItem.id ?? subItem.name] ? (
        <Button variant={"ghost"} size={"iconSm"} disabled>
          {<PinSolid className="w-5 h-5 min-w-5 text-muted-foreground" />}
        </Button>
      ) : (
        <></>
      )}
    </Link>
  );
};

export const StyledNavButton = (
  key: number,
  navItem: NavItem,
  otherValues: {
    isPublicView: boolean;
    userPlans: string[];
    setPublicDialogOpen: (x: boolean) => void;
    setUpgradeDialogOpen: (x: boolean) => void;
    isLoading: boolean;
    userCanAccessEverything?: boolean;
    isMyCollections?: boolean;
    isSidebarExpanded?: boolean;
    smallerPadding?: boolean;
    isProFeature?: boolean;
    showTag?: boolean;
    disabled?: boolean;
    tagText?: string;
  }
) => {
  const router = useRouterState();
  const {
    isLoading,
    isMyCollections = false,
    isPublicView,
    isSidebarExpanded = true,
    setPublicDialogOpen,
    setUpgradeDialogOpen,
    userCanAccessEverything,
    isProFeature,
    showTag,
    disabled,
    tagText,
    userPlans,
  } = otherValues;

  const isActiveLink = navItem.exact
    ? navItem.link && location.pathname === navItem.link
    : navItem.link &&
      isPartialPathMatch({
        pathname: location.pathname,
        targetPath: navItem.link,
        depth: 2,
      });
  const [showProBadge, setShowProBadge] = useState(false);
  const [dropdownStates, setDropdownStates] = useState<Record<string, boolean>>(
    {}
  );
  const [pinnedStates, setPinnedStates] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const dropdown_states = localStorage.getItem("dropdown_states");
    const pinned_states = localStorage.getItem("pinned_states");
    if (dropdown_states) {
      setDropdownStates(JSON.parse(dropdown_states));
    }
    if (pinned_states) {
      setPinnedStates(JSON.parse(pinned_states));
    }
  }, []);

  const handleSublinkClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    if (isPublicView) {
      setPublicDialogOpen(true);
      e.preventDefault();
    }

    if (!isLoading && !userCanAccessEverything) {
      setUpgradeDialogOpen(true);
      e.preventDefault();
    }
  };

  const GenerateDisplayableSubItems = (navItem: NavItem) => {
    // Destructure and set defaults for better readability
    const { subLinks = [], collapsibleSidebar, name } = navItem;

    // Filter and sort the sub-links
    const sortedSubLinks = subLinks
      .slice(1, -2) // Exclude the first and last two items
      .sort((a, b) => {
        const isAPinned = pinnedStates[a.id ?? a.name] ? 1 : 0;
        const isBPinned = pinnedStates[b.id ?? b.name] ? 1 : 0;
        return isBPinned - isAPinned; // Prioritize pinned items
      });

    // Determine if a sub-item should be displayed
    const shouldDisplaySubItem = (subItem: SubItem): boolean => {
      const isDropdownExpanded = collapsibleSidebar
        ? dropdownStates[name]
        : true;

      const isPathMatch =
        subItem.first2Links || subItem.first3Links
          ? isPartialPathMatch({
              pathname: location.pathname,
              targetPath: subItem.first2Links || subItem.first3Links,
              depth: subItem.first2Links ? 2 : 3,
            })
          : subItem.link && router.location.pathname.startsWith(subItem.link);

      const isPinned = pinnedStates[subItem.id ?? subItem.name];

      return isDropdownExpanded || isPathMatch || isPinned;
    };

    // Generate JSX elements for displayable sub-items
    return sortedSubLinks
      .map((subItem, index) => {
        if (!shouldDisplaySubItem(subItem)) return null;

        return (
          <CollectionItem
            key={subItem.id ?? subItem.name}
            isMyCollections={isMyCollections}
            pinnedStates={pinnedStates}
            setPinnedStates={(newPinnedStates: Record<string, boolean>) => {
              localStorage.setItem(
                "pinned_states",
                JSON.stringify(newPinnedStates)
              );
              setPinnedStates(newPinnedStates);
            }}
            index={index}
            isLoading={isLoading}
            handleSublinkClick={handleSublinkClick}
            subItem={subItem}
          />
        );
      })
      .filter((item) => item !== null); // Remove null values
  };

  return (
    <Stack key={key}>
      <Link
        to={navItem.link}
        disabled={disabled ?? false}
        search={(d) => ({
          ...d,
          cacheBuster: Math.random(),
          orderFilter: "Recent",
          sideBarOpen: false,
        })}
        onClick={
          navItem.collapsibleSidebar && isSidebarExpanded
            ? (e) => {
                e.preventDefault();
                localStorage.setItem(
                  "dropdown_states",
                  JSON.stringify({
                    ...dropdownStates,
                    [navItem.name]: !(dropdownStates[navItem.name] ?? false),
                  })
                );
                setDropdownStates({
                  ...dropdownStates,
                  [navItem.name]: !(dropdownStates[navItem.name] ?? false),
                });
              }
            : undefined
        }
      >
        <div
          className={cn(
            buttonVariants({
              variant: isActiveLink ? "brandGradient" : "ghost",
              size: isSidebarExpanded ? "sm" : "iconSm",
              className: `${disabled ? "opacity-50":""} ${isSidebarExpanded ? "w-full justify-start px-2" : ""}`,
            })
          )}
          key={navItem.name}
          onMouseEnter={
            navItem.collapsibleSidebar && isSidebarExpanded
              ? () => setShowProBadge(true)
              : undefined
          }
          onMouseLeave={
            navItem.collapsibleSidebar && isSidebarExpanded
              ? () => setShowProBadge(false)
              : undefined
          }
        >
          {isSidebarExpanded ? (
            <div className="flex items-center">
              {isMyCollections && (navItem.subLinks ?? []).length > 3 ? (
                <img
                  alt={"star"}
                  src={"/images/filled-star.png"}
                  className={"h-5 w-5 min-w-5"}
                />
              ) : (
                <navItem.icon className="w-5 h-5 min-w-5" />
              )}
              {navItem.showPingIcon && (
                <PingCirclesRed/>
              )}
            </div>
          ) : (
            <Tooltip>
              <TooltipTrigger>
                <navItem.icon className="w-5 h-5 min-w-5" />
              </TooltipTrigger>
              <TooltipContent className="flex gap-2 items-center">
                <p>{navItem.name}</p>
                {disabled ?  <Badge className="font-medium size-sx bg-purple-100 hover:bg-purple-100 text-themedestructive">
                    {tagText}
                  </Badge>: null}
              </TooltipContent>
            </Tooltip>
          )}
          {isSidebarExpanded && (
            <div className="flex justify-between gap-3 items-center w-full">
              <div>{navItem.name}</div>
              <div className="flex gap-2 w-full justify-end">
                {isProFeature &&
                  !(
                    userPlans.includes(PRO_PLAN.plan_id) ||
                    userPlans.includes(PRO_PLAN.no_trial_plan_id)
                  ) &&
                  showProBadge && (
                    <Badge className="font-medium size-sx hover:bg-primary">
                      Pro
                    </Badge>
                  )}
                {showTag && (
                  <Badge className="font-medium size-sx bg-purple-100 hover:bg-purple-100 text-themedestructive">
                    {tagText}
                  </Badge>
                )}
                {(isProFeature
                  ? !(
                      userPlans.includes(PRO_PLAN.plan_id) ||
                      userPlans.includes(PRO_PLAN.no_trial_plan_id)
                    )
                    ? isProFeature && !showProBadge
                    : true
                  : true) &&
                  navItem.collapsibleSidebar &&
                  isSidebarExpanded &&
                  (dropdownStates[navItem.name] ? (
                    <ChevronDown className="w-5 h-5 text-muted-foreground" />
                  ) : (
                    <ChevronRight className="w-5 h-5 text-muted-foreground" />
                  ))}
              </div>
            </div>
          )}
        </div>
      </Link>

      {isMyCollections ? (
        navItem?.subLinks && (
          <Stack>
            {/* First item */}
            {navItem.subLinks.length >= 1 &&
              ((navItem.collapsibleSidebar
                ? dropdownStates[navItem.name]
                : true) ||
                (navItem.subLinks[0].first2Links ||
                navItem.subLinks[0].first3Links
                  ? isPartialPathMatch({
                      pathname: location.pathname,
                      targetPath:
                        navItem.subLinks[0].first2Links ||
                        navItem.subLinks[0].first3Links,
                      depth: navItem.subLinks[0].first2Links ? 2 : 3,
                    })
                  : navItem.subLinks[0].link &&
                    router.location?.pathname.startsWith(
                      navItem.subLinks[0].link
                    ))) && (
                <Link
                  key="first-link"
                  onClick={
                    !isMyCollections
                      ? handleSublinkClick
                      : navItem.subLinks[0].openCreateNewBoard || undefined
                  }
                  to={navItem.subLinks[0].link}
                  search={() => ({
                    sideBarOpen: false,
                  })}
                  className={
                    (
                      navItem.subLinks[0].first2Links ||
                      navItem.subLinks[0].first3Links
                        ? isPartialPathMatch({
                            pathname: location.pathname,
                            targetPath:
                              navItem.subLinks[0].first2Links ||
                              navItem.subLinks[0].first3Links,
                            depth: navItem.subLinks[0].first2Links ? 2 : 3,
                          })
                        : navItem.subLinks[0].link &&
                          router.location?.pathname.startsWith(
                            navItem.subLinks[0].link
                          )
                    )
                      ? cn(
                          buttonVariants({
                            variant: "brandGradient",
                            size: "sm",
                            className:
                              "!text-slate-50 gap-1.5 justify-between px-2",
                          })
                        )
                      : cn(
                          buttonVariants({
                            variant: "ghost",
                            size: "sm",
                            className: `text-slate-600 font-normal justify-between px-2 ${
                              !isLoading &&
                              !userCanAccessEverything &&
                              isProFeature
                                ? "opacity-50"
                                : ""
                            }`,
                          })
                        )
                  }
                  activeOptions={{
                    includeSearch: false,
                    exact: true,
                  }}
                >
                  <div className="flex items-center gap-1.5 max-w-full overflow-hidden">
                    <div className={`w-5 h-5`}>
                      {navItem.subLinks[0].link &&
                        router?.location.pathname.startsWith(
                          navItem.subLinks[0].link
                        ) &&
                        navItem.subLinks[0]?.icon}
                    </div>
                    <span
                      className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                    >
                      {navItem.subLinks[0].name}
                    </span>
                  </div>
                  {!isLoading && !userCanAccessEverything && isProFeature && (
                    <LockTagSmall />
                  )}
                  {navItem.subLinks[0].isAddCollection && (
                    <Plus className="w-5 h-5 hover:cursor-pointer" />
                  )}
                </Link>
              )}

            {/* Middle items in scroll area */}
            {/* {navItem.subLinks.length > 3 && (
              <ScrollArea
                className={`${
                  GenerateDisplayableSubItems(navItem).length > 0
                    ? "min-h-[117px] h-fit"
                    : "min-h-0"
                } max-h-[calc(100vh-50rem)] w-full`}
              >
                {GenerateDisplayableSubItems(navItem)}
              </ScrollArea>
            )} */}
            {...GenerateDisplayableSubItems(navItem)}

            {/* Last two items */}
            {navItem.subLinks.length >= 3 &&
              navItem.subLinks.slice(-2).map((subItem, index) =>
                (navItem.collapsibleSidebar
                  ? dropdownStates[navItem.name]
                  : true) ||
                (subItem.first2Links || subItem.first3Links
                  ? isPartialPathMatch({
                      pathname: location.pathname,
                      targetPath: subItem.first2Links || subItem.first3Links,
                      depth: subItem.first2Links ? 2 : 3,
                    })
                  : subItem.link &&
                    router.location.pathname.startsWith(subItem.link)) ? (
                  <Link
                    key={`last-link-${index}`}
                    onClick={
                      !isMyCollections
                        ? handleSublinkClick
                        : subItem.openCreateNewBoard || undefined
                    }
                    to={subItem.link}
                    search={() => ({
                      sideBarOpen: false,
                    })}
                    className={
                      (
                        subItem.first2Links || subItem.first3Links
                          ? isPartialPathMatch({
                              pathname: location.pathname,
                              targetPath:
                                subItem.first2Links || subItem.first3Links,
                              depth: subItem.first2Links ? 2 : 3,
                            })
                          : subItem.link &&
                            router.location.pathname.startsWith(subItem.link)
                      )
                        ? cn(
                            buttonVariants({
                              variant: "brandGradient",
                              size: "sm",
                              className:
                                "!text-slate-50 gap-1.5 justify-between px-2",
                            })
                          )
                        : cn(
                            buttonVariants({
                              variant: "ghost",
                              size: "sm",
                              className: `text-slate-600 font-normal justify-between px-2 ${
                                !isLoading &&
                                !userCanAccessEverything &&
                                isProFeature
                                  ? "opacity-50"
                                  : ""
                              }`,
                            })
                          )
                    }
                    activeOptions={{
                      includeSearch: false,
                      exact: true,
                    }}
                  >
                    <div className="flex items-center gap-1.5 max-w-full overflow-hidden">
                      <div className={`w-5 h-5`}>
                        {subItem.link &&
                          router?.location.pathname.startsWith(subItem.link) &&
                          subItem?.icon}
                      </div>
                      <Text
                        weight={"normal"}
                        size={"sm"}
                        className={`truncate max-w-full overflow-hidden text-ellipsis whitespace-nowrap`}
                      >
                        {subItem.name}
                      </Text>
                    </div>
                    {!isLoading && !userCanAccessEverything && isProFeature && (
                      <LockTagSmall />
                    )}
                    {subItem.isAddCollection && (
                      <Plus className="w-5 h-5 min-w-5 hover:cursor-pointer text-muted-foreground" />
                    )}
                  </Link>
                ) : (
                  <></>
                )
              )}
          </Stack>
        )
      ) : (
        <>
          {navItem?.subLinks && (
            <Stack>
              {navItem.subLinks
                .sort((a, b) => {
                  const aPinned = pinnedStates[a.id ?? a.name] ? 1 : 0;
                  const bPinned = pinnedStates[b.id ?? b.name] ? 1 : 0;
                  return bPinned - aPinned; // Sort by pinned state (true values first)
                })
                .map((subItem, index) =>
                  (navItem.collapsibleSidebar
                    ? dropdownStates[navItem.name]
                    : true) ||
                  (subItem.first2Links || subItem.first3Links
                    ? isPartialPathMatch({
                        pathname: location.pathname,
                        targetPath: subItem.first2Links || subItem.first3Links,
                        depth: subItem.first2Links ? 2 : 3,
                      })
                    : subItem.link &&
                      router.location.pathname.startsWith(subItem.link)) ||
                  pinnedStates[subItem.id ?? subItem.name] ? (
                    <CollectionItem
                      isMyCollections={isMyCollections}
                      pinnedStates={pinnedStates}
                      setPinnedStates={(
                        pinnedStates: Record<string, boolean>
                      ) => {
                        localStorage.setItem(
                          "pinned_states",
                          JSON.stringify(pinnedStates)
                        );
                        setPinnedStates(pinnedStates);
                      }}
                      index={index}
                      isLoading={isLoading}
                      userCanAccessEverything={userCanAccessEverything}
                      handleSublinkClick={handleSublinkClick}
                      subItem={subItem}
                      isProFeature={isProFeature}
                    />
                  ) : (
                    <></>
                  )
                )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
