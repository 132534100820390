import { FigmaLogo } from "@/assets/template-logo.tsx";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import { AddToBoard } from "@/components/custom-components/AddToBoard";
import { AdHeader } from "@/components/custom-components/AdHeader";
import { DotsLoader } from "@/components/custom-components/Loader";
import { TemplatesFooter } from "@/components/custom-components/TemplatesHeader";
import EmailInspirationDetails from "@/components/EmailInspirationDetails";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import LanderAdInspirationDetails from "@/components/LanderInspirationDetails";
import { Badge } from "@/components/shadcn/badge";
import { Button } from "@/components/shadcn/button";
import TemplateDetails from "@/components/TemplateDetails";
import { GenericCardProps } from "@/components/templates/LandingAdGridView";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { cn } from "@/lib/utils.ts";
import { trpc } from "@/utils/trpc.ts";
import { BrandFigma, Download, Layout, Link, Lock } from "@mynaui/icons-react";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentPropsWithoutRef, forwardRef, useState } from "react";
import { EmailFeedContent } from "../../../../../../src/server/services/emails/definitions";
import { BlurredOverlay, SharedOverlay } from "../AdCard";

import { useCapturePostHogEvent } from "@/hooks/useCapturePosthogEvent.tsx";
import {
  REQUEST_TEMPLATE_DESCRIPTION,
  REQUEST_TEMPLATE_MESSAGE,
} from "@/_shared/constants";
import { Text } from "@/components/custom-components";
import { AirtableLandingPageRecord } from "../../../../../../src/shared";

const adCardStyles = cva("w-full relative rounded bg-[#F5F5F5]");

export interface LandingAdDataProps {
  Screenshot: string;
  ID: string;
  created: string;
  category?: string;
  isSaved?: boolean;
  imageUrl?: string | null;
  brandId?: string | null;
  brandName?: string | null;
  brandImage?: string | null;
}

export type LandingFeedProps = {
  landerId: number;
  landerUrl: string;
  brandName: string | null;
  brandImage: string | null;
  desktopScreenshot: string | null;
  mobileScreenshot: string | null;
  brandId: string | null;
  isSaved?: boolean;
  hasUserRequestedTemplate?: boolean;
  createdAt?: string;
  requestCount?: string | number;
  template?:AirtableLandingPageRecord | null;
};

export interface AdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingAdDataProps;
  type: "email" | "landing-page";
  showTemplateButton?: boolean;
  showRecentButton?: boolean;
  IsLocked?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
}

export interface LandingAdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: LandingFeedProps;
  IsLocked?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  IsPublic?: boolean;
  IsRequested?: boolean;
  hasRequestLimitReached?: boolean;
}
export interface EmailAdCardProps
  extends ComponentPropsWithoutRef<"div">,
    VariantProps<typeof adCardStyles> {
  adData: EmailFeedContent;
  IsLocked?: boolean;
  IsShared?: boolean;
  openDetailsOnInit?: boolean;
  IsPublic?: boolean;
  IsRequested?: boolean;
}

export const LandingAdCard = forwardRef<HTMLDivElement, AdCardProps>(
  (
    {
      className,
      showTemplateButton = false,
      showRecentButton = false,
      IsLocked = false,
      openDetailsOnInit = false,
      IsShared = false,
      adData,
      type,
      ...props
    },
    ref
  ) => {
    const [, copyToClipboard] = useCopyToClipboard();
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [showTemplate, setShowTemplate] = useState<boolean>(true);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    return (
      <div
        onMouseEnter={
          showTemplateButton || showRecentButton || IsShared
            ? () => {
                setShowTemplate(false);
              }
            : () => {}
        }
        onMouseLeave={
          showTemplateButton || showRecentButton || IsShared
            ? () => {
                setShowTemplate(true);
              }
            : () => {}
        }
        ref={ref}
        className={cn(
          adCardStyles({
            className: `${className} ${IsLocked && "pointer-events-none"}`,
          })
        )}
        {...props}
      >
        {detailsShow && adData && (
          <TemplateDetails
            landingData={adData}
            type={type}
            open={detailsShow}
            onCloseDrawer={() => setDetailsShow(false)}
          />
        )}

        <div className={"rounded "}>
          <ScrollArea className={"w-full h-[35.4375rem]"}>
            <img
              alt={
                type === "email"
                  ? "screenshot of a email template"
                  : "screenshot of a landing page template"
              }
              loading={"lazy"}
              className={"w-full rounded-md"}
              src={adData.imageUrl ?? adData.Screenshot}
            />
          </ScrollArea>
        </div>
        {showTemplate && IsShared && <SharedOverlay />}
        <div
          className={
            "absolute bg-opacity-40 inset-x-0 inset-y-0 bg-slate-900 rounded flex justify-between opacity-0 hover:opacity-100 flex-col items-stretch transition-opacity duration-300 cursor-pointer"
          }
        >
          <div className="flex justify-between items-center p-3">
            <GetLandingAdTemplate type={type} Ad={adData} />

            <div className="flex gap-2">
              {adData && (
                <ManuallySaveLandingAdToBoard
                  open={saveOpen}
                  onOpenChange={setSaveOpen}
                  data={adData}
                  variant={"secondary"}
                  type={type}
                />
              )}
              <div className={"space-x-2"}>
                <Tooltip>
                  <TooltipTrigger>
                    <a
                      href={adData.imageUrl ?? adData.Screenshot}
                      download={"image.png"}
                    >
                      <Button variant={"secondary"} size={"icon"}>
                        <Download className="w-5 h-5" />
                      </Button>
                    </a>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Download as image</p>
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.origin}/share/templates/${type === "email" ? "emails" : type === "landing-page" ? "landing-pages" : ""}/${adData.ID}`
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      size={"icon"}
                      variant={"secondary"}
                    >
                      <Link className="w-5 h-5 " />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Share a public link</p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className="h-full w-full bg-slate-900 bg-opacity-0"
            onClick={() => setDetailsShow(true)}
          />
          <div className="flex justify-between p-3">
            <TemplatesFooter
              footerTitle={
                location.pathname.includes("feeds/templates/landing-pages")
                  ? `${adData.brandName ? `${adData.brandName} `:""}Lander Template`
                  : `${adData.brandName ? `${adData.brandName} `:""}Email Template`
              }
              brandImage={adData.brandImage}
              footerType=""
              titleColor="text-popover"

            />
          </div>
        </div>
        {showTemplateButton && showTemplate && (
          <div
            className={
              "absolute inset-x-0 inset-y-0 rounded justify-between flex flex-col items-stretch transition-opacity duration-300"
            }
          >
            <div className="flex justify-between p-3">
              {showTemplateButton ? (
                <Badge variant={"destructivePurple"} className="gap-1">
                  <Layout className="w-4 h-4" />
                  Template
                </Badge>
              ) : showRecentButton ? (
                <Badge
                  variant={"destructivePurple"}
                  className="gap-1 bg-[#0F172A66]"
                >
                  <div className="w-[0.333rem] h-[0.333rem] rounded-full bg-green-400" />
                  Just Added
                </Badge>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        {IsLocked && !IsShared && <BlurredOverlay />}
      </div>
    );
  }
);

type templateProps = {
  Ad: GenericCardProps;
  IsPublic?: boolean;
  type: "email" | "landing-page";
  buttonVersion?: boolean;
};

export const GetLandingAdTemplate = (props: templateProps) => {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);

  const { capture } = useCapturePostHogEvent();

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.ID.toString(),
      type: props.type,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setAccessPromptDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      if (props.type === "email") {
        capture("USER_EMAIL_TEMPLATE_FIGMA_COPIED", {
          templateId: props.Ad.ID,
        });
      } else if (props.type === "landing-page") {
        capture("USER_LANDING_PAGE_TEMPLATE_FIGMA_COPIED", {
          templateId: props.Ad.ID,
        });
      }
      showToastNotification("success", {
        message: "Successfully copied template. CMD + V in Figma",
      });
    }
  };

  return (
    <>
      {accessPromptDialogOpen && (
        <FreeAccessPrompt
          open={accessPromptDialogOpen}
          onOpenChange={() => setAccessPromptDialogOpen(false)}
        />
      )}

      {props.buttonVersion ? (
        <Button
          className="w-full"
          onClick={handleCopyClick}
          loading={isLoading}
        >
          <div className="flex gap-2 items-center">
            <Text weight={"bold"} size="sm">
              Copy to Figma
            </Text>
            <BrandFigma className="w-5 h-5 min-w-5" />
          </div>
        </Button>
      ) : (
        <div className={"text-nowrap"}>
          <div className={"flex items-center gap-3"}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className={"flex gap-3 items-center"}>
                  {isLoading ? (
                    <div>
                      <DotsLoader />
                    </div>
                  ) : (
                    <div
                      className="w-9 h-9 rounded-full bg-black flex items-center justify-center cursor-pointer"
                      onClick={handleCopyClick}
                    >
                      <FigmaLogo />
                    </div>
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Export to Figma</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

export const LandingFeedAdCard = forwardRef<HTMLDivElement, LandingAdCardProps>(
  (
    {
      adData,
      IsPublic,
      IsRequested,
      IsLocked = false,
      openDetailsOnInit = false,
      ...props
    },
    ref
  ) => {
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const { data: permissions } = trpc.permissions.useQuery(null, {});

    const { mutateAsync: requestAdTemplate } =
      trpc.requestLanderTemplate.useMutation();
    const utils = trpc.useUtils();

    const [, copyToClipboard] = useCopyToClipboard();

    return (
      <div
        ref={ref}
        className={`h-[41.25rem] cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
        ${IsLocked && "filter blur-md pointer-events-none"}`}
        {...props}
      >
        {detailsShow && (
          <LanderAdInspirationDetails
            open={detailsShow}
            onOpenChange={() => setDetailsShow(false)}
            data={adData}
            IsPublic={false}
          />
        )}
        <div className="h-[41.25rem] relative w-full">
          {adData.desktopScreenshot && (
            <img
              alt={"screenshot of a landing page"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full rounded-md hidden lg:flex object-cover object-top"
              }
              src={adData.desktopScreenshot}
            />
          )}
          {adData.mobileScreenshot && (
            <img
              alt={"screenshot of a landing page"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full rounded-md flex lg:hidden object-cover object-top"
              }
              src={adData.mobileScreenshot}
            />
          )}
          <div
            className={`rounded-md p-3 absolute top-0 left-0 w-full h-[41.25rem] flex flex-col justify-between bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100`}
          >
            <div className={"flex justify-between gap-5"}>
              <div>
                {!IsRequested && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => {
                          if (permissions === undefined) {
                            showToastNotification("warning", {
                              description: REQUEST_TEMPLATE_DESCRIPTION,
                              message: REQUEST_TEMPLATE_MESSAGE,
                            });
                          } else if (permissions.hasRequestLimitReached) {
                            showToastNotification("warning", {
                              description: `You're only able to request ${permissions.requestLimit} templates per month on the Basic Plan. Restarts on ${permissions.limitNextUpdateAt}.`,
                              message: "You’ve maxed out requests",
                            });
                          } else {
                            setAdRequested(true);
                            requestAdTemplate({
                              landerID: adData.landerId,
                            }).then(() => {
                              showToastNotification("success", {
                                message: `${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} Template Requests left!`,
                                description: `You have ${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} template requests left for the month.`,
                              });
                              utils.permissions.invalidate();

                              setTimeout(() => {
                                setAdRequested(false);
                              }, 5000);
                            });
                          }
                        }}
                        variant={"secondary"}
                        className={`gap-2 ${(permissions?.hasRequestLimitReached ?? true) ? "opacity-50" : ""}`}
                      >
                        {(permissions?.hasRequestLimitReached ?? true) ? (
                          <Lock className="w-5 h-5 mr-2" />
                        ) : (
                          <></>
                        )}

                        {adRequested ? "Requested!" : "Request"}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Request Template</p>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
              <div className={"flex gap-2"}>
                {!IsPublic && adData && (
                  <ManuallySaveFeedAdToBoard
                    open={saveOpen}
                    onOpenChange={setSaveOpen}
                    data={adData}
                    variant={"secondary"}
                  />
                )}

                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.origin}/share/inspiration/landing-pages/${adData.landerId}`
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      variant={"secondary"}
                      size={"icon"}
                    >
                      <Link />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Share a public link</p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
            <div
              className={"flex flex-col flex-1"}
              onClick={() => setDetailsShow(true)}
            ></div>
            <div className="text-white flex justify-between">
              {adData.brandId && (
                <AdHeader
                  brandName={adData.brandName}
                  brandId={adData.brandId}
                  Created={undefined}
                  avatar={adData.brandImage}
                  adRunningDays={undefined}
                  status={undefined}
                />
              )}
              <div>
                <span className={"text-xs text-nowrap text-themeinput"}>
                  Published{" "}
                  {adData.createdAt &&
                    new Date(adData.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                </span>
              </div>
            </div>
          </div>
        </div>
        {IsLocked && <BlurredOverlay />}
      </div>
    );
  }
);
export const EmailFeedAdCard = forwardRef<HTMLDivElement, EmailAdCardProps>(
  (
    {
      adData,
      IsLocked = false,
      IsRequested,
      openDetailsOnInit = false,
      IsPublic = false,
      ...props
    },
    ref
  ) => {
    const [, copyToClipboard] = useCopyToClipboard();
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const utils = trpc.useUtils();

    const { mutateAsync: requestEmailTemplate } =
      trpc.requestEmail.useMutation();
    const [detailsShow, setDetailsShow] = useState<boolean>(openDetailsOnInit);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);
    const { data: permissions } = trpc.permissions.useQuery(null, {});

    return (
      <div
        ref={ref}
        className={`h-[41.25rem] cursor-pointer relative rounded-md bg-[#F5F5F5] break-inside-avoid-column
        ${IsLocked && "filter blur-md pointer-events-none"}`}
        {...props}
      >
        {detailsShow && (
          <EmailInspirationDetails
            open={detailsShow}
            onOpenChange={() => setDetailsShow(false)}
            data={adData}
            IsPublic={false}
          />
        )}

        <div className="h-[41.25rem] relative w-full overflow-hidden rounded-md">
          {adData.imageUrl && (
            <img
              alt={"screenshot of an email feed"}
              loading={"lazy"}
              className={
                "h-[41.25rem] w-full object-cover object-top scale-[200%] origin-top"
              }
              src={adData.imageUrl}
            />
          )}
          <div
            className={`rounded-md p-3 absolute top-0 left-0 w-full h-[41.25rem] flex flex-col justify-between bg-slate-900 bg-opacity-50 opacity-0 transition-opacity duration-300 hover:opacity-100`}
          >
            <div className={"flex justify-between gap-5"}>
              <div>
                {!IsRequested && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => {
                          if (permissions === undefined) {
                            showToastNotification("warning", {
                              description: REQUEST_TEMPLATE_DESCRIPTION,
                              message: REQUEST_TEMPLATE_MESSAGE,
                            });
                          } else if (permissions.hasRequestLimitReached) {
                            showToastNotification("warning", {
                              description: `You're only able to request ${permissions.requestLimit} templates per month on the Basic Plan. Restarts on ${permissions.limitNextUpdateAt}.`,
                              message: "You’ve maxed out requests",
                            });
                          } else {
                            setAdRequested(true);
                            requestEmailTemplate({
                              emailId: adData.id,
                            }).then(() => {
                              showToastNotification("success", {
                                message: `${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} Template Requests left!`,
                                description: `You have ${permissions.limitLeft > 0 ? permissions.limitLeft - 1 : 0} template requests left for the month.`,
                              });
                              utils.permissions.invalidate();

                              setTimeout(() => {
                                setAdRequested(false);
                              }, 5000);
                            });
                          }
                        }}
                        variant={"secondary"}
                        className={`${(permissions?.hasRequestLimitReached ?? true) ? "opacity-50" : ""}`}
                      >
                        {(permissions?.hasRequestLimitReached ?? true) ? (
                          <Lock className="w-5 h-5 mr-2" />
                        ) : (
                          <></>
                        )}

                        {adRequested ? "Requested!" : "Request"}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Request Template</p>
                    </TooltipContent>
                  </Tooltip>
                )}
              </div>
              <div className={"flex gap-2"}>
                {!IsPublic && adData && (
                  <ManuallySaveFeedEmailToBoard
                    open={saveOpen}
                    onOpenChange={setSaveOpen}
                    data={adData}
                    variant={"secondary"}
                  />
                )}

                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.origin}/share/inspiration/emails/${adData.id}`
                        );
                        showToastNotification("success", {
                          message: "Copied!",
                        });
                      }}
                      variant={"secondary"}
                      size={"icon"}
                    >
                      <Link />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Share a public link</p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </div>
            <div
              className={"flex flex-col flex-1"}
              onClick={() => setDetailsShow(true)}
            ></div>
            <div className="text-white flex justify-between">
              {adData.brandId && (
                <AdHeader
                  brandName={adData.brandName}
                  brandId={adData.brandId}
                  Created={undefined}
                  avatar={adData.brandImage}
                  adRunningDays={undefined}
                  status={undefined}
                />
              )}
              <div>
                <span className={"text-xs text-nowrap text-themeinput"}>
                  Published{" "}
                  {adData.date &&
                    new Date(adData.date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                </span>
              </div>
            </div>
          </div>
        </div>
        {IsLocked && <BlurredOverlay />}
      </div>
    );
  }
);

export function ManuallySaveFeedEmailToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: EmailFeedContent;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="email-ad"
      adData={{ id: data.id }}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}
export function ManuallySaveFeedAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingFeedProps;
  variant: "outline" | "secondary";
}) {
  return (
    <AddToBoard
      itemType="lander"
      landerData={{ landerId: data.landerId }}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}

export function ManuallySaveLandingAdToBoard({
  open,
  onOpenChange,
  data,
  variant,
  type,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: LandingAdDataProps;
  variant: "outline" | "secondary";
  type: "email" | "landing-page";
}) {
  return (
    <AddToBoard
      itemType={type == "email" ? "email-template" : "landing-page-template"}
      emailLanderTemplateData={data}
      initialSavedState={data.isSaved || false}
      open={open}
      onOpenChange={onOpenChange}
      variant={variant}
    />
  );
}
