import { useEffect, useMemo, useRef, useState } from 'react';
import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router';
import { trpc } from '@/utils/trpc.ts';
import { ProtectedArea } from '@/components/protectedArea.tsx';
import { useOnClickOutside } from '@/hooks/useClickOutside.tsx';
import { Sidebar } from '@/components/templates/Sidebar';
import FreeTrialEnding from '@/components/FreeTrialEnding.tsx';
import { ManageAccountV2 } from '@/components/templates/AppHeader/index.tsx';
import { SidebarButton } from '@/components/SidebarButton.tsx';

type FeedsProps = {
  sideBarOpen?: boolean | undefined;
  sidebarCollapsed?: boolean | undefined;
  cacheBuster?: number | undefined;
};

export const Route = createFileRoute('/feeds')({
  component: Feeds,
  validateSearch: (search: Record<string, unknown>): FeedsProps => {
    return {
      sideBarOpen: search.sideBarOpen as boolean | undefined,
      sidebarCollapsed: search.sidebarCollapsed as boolean | undefined,
      cacheBuster: search.cacheBuster as number | undefined,
    };
  },
});

export type PermissionsData = {
  userCanAccessEverything: boolean;
  userCanAccessAds: boolean;
  userCanAccessExperts: boolean;
  ID: string;
  usersPlans: string[];
  email: string;
};

function Feeds() {
  const { sideBarOpen, sidebarCollapsed } = Route.useSearch();
  const [trialEndingPromptShow, setTrialEndingPromptShow] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { data: permissionData, isLoading: fetchingPermissions } =
    trpc.permissions.useQuery(null, {});

  const ref = useRef(null);
  const handleClickOutside = () => {
    if (sideBarOpen) {
      navigate({
        search: (old) => ({
          ...old,
          sideBarOpen: false,
        }),
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  };
  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    if (!sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sidebarCollapsed: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  }, [sideBarOpen, navigate]);

  const shouldShowTrialPrompt = useMemo(() => {
    return (
      permissionData &&
      !fetchingPermissions &&
      permissionData.usersPlans.length === 0 &&
      !permissionData.userCanAccessEverything
    );
  }, [permissionData, fetchingPermissions]);

  useEffect(() => {
    if (shouldShowTrialPrompt) {
      setTrialEndingPromptShow(true);
    }
  }, [shouldShowTrialPrompt]);

  return (
    <ProtectedArea>
      {trialEndingPromptShow && permissionData && !fetchingPermissions && (
        <FreeTrialEnding
          open={trialEndingPromptShow}
          onOpenChange={() => setTrialEndingPromptShow(true)}
          permissionData={permissionData}
        />
      )}

      <>
        <div className={'relative flex h-screen overflow-y-hidden'}>
          <div
            className={`absolute lg:ml-0 lg:static ${sidebarCollapsed ? 'lg:w-[3rem]' : 'lg:w-56'} w-full flex-sidebar shrink-0 z-30 transition-all duration-500 ${sideBarOpen ? 'ml-0' : '-ml-[100vw]'}`}
          >
            <div ref={ref}>
              <Sidebar
                sidebarCollapsed={sidebarCollapsed}
                isPublicView={false}
              />
            </div>
          </div>
          <div className={'w-full flex-auto overflow-x-hidden overflow-y-auto'}>
            <div
              className={`${sidebarCollapsed ? 'max-w-[2000px]' : 'max-w-pagemax'}`}
            >
              <div className="lg:hidden flex w-full h-12 bg-slate-50 items-center justify-between p-3">
                <img
                  alt={'Creative OS'}
                  src={'/images/cos-logo-nobg.png'}
                  className={'h-6'}
                />
                <div className="items-center flex gap-3">
                  <ManageAccountV2 showName={false} />
                  <SidebarButton sideBarOpen={sideBarOpen} />
                </div>
              </div>
              {/*<button*/}
              {/*  className={`md:hidden w-auto px-16 py-2 mx-10 mt-2 text-sm font-semibold text-white rounded-full ${sidebarState ? "bg-red-500" : "bg-blue-500"}`}*/}
              {/*  onClick={toggleSidebar}*/}
              {/*>*/}
              {/*  Debug Toggle Box*/}
              {/*</button>*/}

              <div
                className={
                  location.pathname.startsWith(
                    '/feeds/creative-toolkit/ad-test-calculator',
                  )
                    ? ''
                    : `p-3 lg:p-6 `
                }
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </>
    </ProtectedArea>
  );
}
