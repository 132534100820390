import {
  AdDataProps,
  ManuallySaveTemplateToBoard,
} from "@/components/templates/AdCard";
import { Link } from "@tanstack/react-router";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { Button } from "./shadcn/button";
import { Download } from "@mynaui/icons-react";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import showToastNotification from "@/hooks/useShowToast";
import { Link as LinkIcon } from "@mynaui/icons-react";
import { Text } from "./custom-components";

export const TemplateDetailsAdMedia = ({
  data,
  saveOpen,
  setSaveOpen,
}: {
  data: AdDataProps;
  saveOpen: boolean;
  setSaveOpen: (x: boolean) => void;
}) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="p-3 lg:p-4 w-full flex flex-col items-center">
      <div className={"flex gap-5 justify-between w-full"}>
        <div className={`flex gap-1 items-center`}>
          {data.Ad.brandImage && data.Ad.brandId ? (
            <Link
              to={`/feeds/brands/$brandID`}
              params={{ brandID: data.Ad.brandId }}
            >
              <img
                src={data.Ad.brandImage}
                alt={data.Ad.brandName || "Brand name"}
                className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
              ></img>
            </Link>
          ) : (
            <img
              src={"/images/emblam.png"}
              alt={"CreativeOs"}
              className={`bg-[#BCBCBC] rounded-full w-8 h-8 object-contain`}
            />
          )}
          <div>
            {data.Ad.brandName && data.Ad.brandId ? (
              <Link
                to={`/feeds/brands/$brandID`}
                params={{ brandID: data.Ad.brandId }}
              >
                <h4 className={`truncate font-[500] text-sm`}>
                  {data.brandName}
                </h4>
              </Link>
            ) : (
              <h4 className={`truncate font-[500] text-sm`}>Ad Template</h4>
            )}
            <p className={"text-xs text-thememutedforeground"}>by CreativeOS</p>
          </div>
        </div>

        <div className={"flex gap-2"}>
          {!data.IsPublic && (
            <ManuallySaveTemplateToBoard
              open={saveOpen}
              onOpenChange={setSaveOpen}
              data={data}
              variant="outline"
            />
          )}

          {data.Ad.imageUrl && (
            <Tooltip>
              <TooltipTrigger asChild>
                <a href={data.Ad.imageUrl} download={"image.png"}>
                  <Button variant={"outline"} size={"icon"}>
                    <Download className="w-5 h-5" />
                  </Button>
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <p>Download as image</p>
              </TooltipContent>
            </Tooltip>
          )}

          <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={() => {
                  copyToClipboard(
                    `${window.location.origin}/share/templates/${data.Ad.atID}`
                  );
                  showToastNotification("success", {
                    message: "Copied!",
                  });
                }}
                variant="outline"
                size={"icon"}
              >
                <LinkIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Share a public link</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div
        className={
          "lg:max-w-[23.25rem] w-full border border-themeborder bg-white rounded my-4"
        }
      >
        <div className={"px-2 py-3"}>
          <Text weight={"semibold"} size={"sm"} className={"text-foreground truncate m-3"}>
            Template By Creative OS
          </Text>
        </div>
        <div>
          <img
            className="w-full"
            alt="ad display"
            loading="lazy"
            src={data.Ad.imageUrl ?? data.Ad["Example Image"][0].url}
          />
        </div>
      </div>
    </div>
  );
};
