import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Ad, DiscoverGridView } from '@/components/templates/DiscoverGridView';
import { ErrorDisplay } from '@/components/error.tsx';
import { trpc } from '@/utils/trpc.ts';
import { Loader } from '@/components/custom-components/Loader';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { AdFilter, Stack, Text } from '@/components/custom-components';
import {
  FormatFilter,
  PlatformFilter,
  ScoreFilters,
  StatusFilters,
  TimeLiveFilters,
} from '../../../../src/server/types';
import { otherFilterOptions } from '@/utils/data/filters.ts';
import { z } from 'zod';
import { orderFilter as OrderFilterType } from '../../../../src/shared/airtable';
import { getInspirationTabs } from '@/utils/data/feeds.ts';
import { OrderFilterSelect } from '@/components/ad-inspiration/OrderFilterSelect.tsx';
import { FeatureTabs } from '@/components/custom-components/FeatureTabs/index.tsx';
import { Button } from '@/components/shadcn/button.tsx';
import { Shuffle } from '@mynaui/icons-react';
import {
  AdFeedFilterOption,
  AdSelectedFilters,
  TemplateAdSearchParams,
} from '@/hooks/useFilterFeed.tsx';
import { featureFlagKeys } from '@/utils/data/featureFlags.ts';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { PostHogEvents } from '@/hooks/useCapturePosthogEvent.tsx';
import { InspirationRequestNotifier } from '@/components/custom-components/InspirationRequestNotifier';

export const Route = createFileRoute('/feeds/inspiration/')({
  component: All,
  validateSearch: (search: Record<string, unknown>): TemplateAdSearchParams => {
    const industry = search?.industry as string | undefined;
    const platforms = search?.platforms as string | undefined;
    const formats = search?.formats as string | undefined;
    const timeLive = search?.timeLive as string | undefined;
    const scores = search?.scores as string | undefined;
    const statuses = search?.statuses as string | undefined;
    const brands = search?.brands as string | undefined;
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      industry,
      platforms,
      formats,
      timeLive,
      scores,
      statuses,
      sideBarOpen,
      orderFilter,
      brands,
    };
  },
});

function All() {
  const navigate = useNavigate();

  const {
    industry: queryIndustry,
    platforms: queryPlatforms,
    formats: queryFormats,
    timeLive: queryTimeLive,
    scores: queryScore,
    statuses: queryStatus,
    orderFilter,
    brands: queryBrands,
  } = Route.useSearch();

  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);
  const [filterOptions, setFilterOptions] = useState<AdFeedFilterOption[]>([]);
  const [cursor, setCursor] = useState(1);
  const posthog = usePostHog();

  const [selectedFilters, setSelectedFilters] = useState<AdSelectedFilters>({
    categories: queryIndustry ? queryIndustry.split(',') : undefined,
    platforms: queryPlatforms
      ? (queryPlatforms.split(',') as PlatformFilter[])
      : undefined,
    formats: queryFormats
      ? (queryFormats.split(',') as FormatFilter[])
      : undefined,
    timeLive: queryTimeLive
      ? (queryTimeLive.split(',') as TimeLiveFilters[])
      : undefined,
    scores: queryScore ? (queryScore.split(',') as ScoreFilters[]) : undefined,
    statuses: queryStatus
      ? (queryStatus.split(',') as StatusFilters[])
      : undefined,
    brands: queryBrands ? (queryBrands.split(',') as string[]) : undefined,
  });

  // Get the page industry to be passed to the filter
  const { data: pageIndustry } = trpc.getPageCategories.useQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  const { data: brands } = trpc.getBrands.useQuery(undefined, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (pageIndustry && brands && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Avoid redundant updates
        return [
          ...otherFilterOptions,
          {
            title: 'Industry',
            counter: 0,
            optionItems: pageIndustry.map((i) => ({
              label: i.name,
              value: false,
            })),
          },
          {
            title: 'Brand',
            counter: 0,
            optionItems: brands.map((i) => ({
              label: i.brandName,
              logo: i.brandImage,
              value: false,
              id: i.brandId,
            })),
          },
        ];
      });
    }
  }, [pageIndustry, brands, filterOptions.length]);

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (filterOptions.length === 0 || !selectedFilters) return;

    const updatedOptions = filterOptions.map((option) => ({
      ...option,
      optionItems: option.optionItems.map((item) => ({
        ...item,
        value:
          (option.title === 'Industry' &&
            selectedFilters.categories?.includes(item.label)) ||
          (option.title === 'Brand' &&
            selectedFilters.brands?.includes(item.id ?? '')) ||
          (option.title === 'Platform' &&
            selectedFilters.platforms?.includes(
              item.label as PlatformFilter,
            )) ||
          (option.title === 'Format' &&
            selectedFilters.formats?.includes(item.label as FormatFilter)) ||
          (option.title === 'Time Live' &&
            selectedFilters.timeLive?.includes(
              item.label as TimeLiveFilters,
            )) ||
          (option.title === 'Score' &&
            selectedFilters.scores?.includes(item.label as ScoreFilters)) ||
          (option.title === 'Status' &&
            selectedFilters.statuses?.includes(item.label as StatusFilters)),
      })),
    }));

    setFilterOptions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(updatedOptions)) return prev;
      return updatedOptions;
    });
  }, [filterOptions, selectedFilters]);

  const updateQueryString = useCallback(
    (params: {
      industry?: string;
      platforms?: string;
      formats?: string;
      timeLive?: string;
      scores?: string;
      statuses?: string;
      brands?: string;
    }) => {
      const searchParams = new URLSearchParams();

      if (params.industry) searchParams.set('industry', params.industry);
      if (params.brands) searchParams.set('brands', params.brands);
      if (params.platforms) searchParams.set('platforms', params.platforms);
      if (params.formats) searchParams.set('formats', params.formats);
      if (params.timeLive) searchParams.set('timeLive', params.timeLive);
      if (params.scores) searchParams.set('scores', params.scores);
      if (params.statuses) searchParams.set('statuses', params.statuses);

      navigate({
        to: '/feeds/inspiration',
        replace: true,
        search: (old) => {
          return { ...old, ...params, orderFilter };
        },
      });
    },
    [navigate],
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedIndustry: string[] = [];
      const selectedBrands: string[] = [];
      const selectedPlatforms: PlatformFilter[] = [];
      const selectedFormats: FormatFilter[] = [];
      const selectedTimeLive: TimeLiveFilters[] = [];
      const selectedScore: ScoreFilters[] = [];
      const selectedStatus: StatusFilters[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === 'Industry') selectedIndustry.push(item.label);
            if (group.title === 'Brand') selectedBrands.push(`${item.id}`);
            if (group.title === 'Platform')
              selectedPlatforms.push(item.label as PlatformFilter);
            if (group.title === 'Format')
              selectedFormats.push(item.label as FormatFilter);
            if (group.title === 'Time Live')
              selectedTimeLive.push(item.label as TimeLiveFilters);
            if (group.title === 'Score')
              selectedScore.push(item.label as ScoreFilters);
            if (group.title === 'Status')
              selectedStatus.push(item.label as StatusFilters);
          }
        });
      });

      setSelectedFilters({
        categories: selectedIndustry.length > 0 ? selectedIndustry : undefined,
        brands: selectedBrands.length > 0 ? selectedBrands : undefined,
        platforms: selectedPlatforms.length > 0 ? selectedPlatforms : undefined,
        formats: selectedFormats.length > 0 ? selectedFormats : undefined,
        timeLive: selectedTimeLive.length > 0 ? selectedTimeLive : undefined,
        scores: selectedScore.length > 0 ? selectedScore : undefined,
        statuses: selectedStatus.length > 0 ? selectedStatus : undefined,
      });

      updateQueryString({
        industry:
          selectedIndustry.length > 0 ? selectedIndustry.join(',') : undefined,
        brands:
          selectedBrands.length > 0 ? selectedBrands.join(',') : undefined,
        platforms:
          selectedPlatforms.length > 0
            ? selectedPlatforms.join(',')
            : undefined,
        formats:
          selectedFormats.length > 0 ? selectedFormats.join(',') : undefined,
        timeLive:
          selectedTimeLive.length > 0 ? selectedTimeLive.join(',') : undefined,
        scores: selectedScore.length > 0 ? selectedScore.join(',') : undefined,
        statuses:
          selectedStatus.length > 0 ? selectedStatus.join(',') : undefined,
      });
    },
    [updateQueryString],
  );

  useEffect(() => {
    if (!selectedFilters) return;

    const params: Record<string, string | undefined> = {};

    if (selectedFilters.categories)
      params.industry = selectedFilters.categories.join(',');
    if (selectedFilters.brands)
      params.brands = selectedFilters.brands.join(',');
    if (selectedFilters.platforms)
      params.platforms = selectedFilters.platforms.join(',');
    if (selectedFilters.formats)
      params.formats = selectedFilters.formats.join(',');
    if (selectedFilters.timeLive)
      params.timeLive = selectedFilters.timeLive.join(',');
    if (selectedFilters.scores)
      params.scores = selectedFilters.scores.join(',');
    if (selectedFilters.statuses)
      params.statuses = selectedFilters.statuses.join(',');
  }, [selectedFilters]);

  const {
    data: inspirationFeed,
    fetchNextPage,
    refetch,
    isLoading,
    isError,
    hasNextPage,
    isFetchingNextPage,
  } = trpc.filterAdFeed.useInfiniteQuery(
    {
      ...selectedFilters,
      order: orderFilter,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    },
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      !isLoading &&
      inspirationFeed &&
      allData &&
      allData.length &&
      inspirationFeed.pages[inspirationFeed.pages.length - 1]?.ads.length > 0
    ) {
      fetchNextPage();
    }
  }, [
    inView,
    hasNextPage,
    fetchNextPage,
    inspirationFeed,
    isLoading,
    isFetchingNextPage,
    allData,
  ]);

  useEffect(() => {
    if (!inspirationFeed) return;

    const uniqueAds = new Map<number, Ad>();
    for (const page of inspirationFeed.pages) {
      page.ads.forEach((ad) => uniqueAds.set(ad.id, ad));
    }

    setAllData((prevData) => {
      const newData = Array.from(uniqueAds.values());
      if (JSON.stringify(prevData) === JSON.stringify(newData)) return prevData; // Avoid redundant updates
      return newData;
    });
  }, [inspirationFeed]);

  useEffect(() => {
    if (selectedFilters) {
      setCursor((prevCursor) => {
        if (prevCursor === 1) return prevCursor; // Avoid unnecessary reset
        return 1;
      });
    }
  }, [selectedFilters]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }
  const flagEnabled = useFeatureFlagEnabled(featureFlagKeys.emailFeeds);
  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    if (flagEnabled !== undefined) {
      setIsResolved(true); // Mark the flag as resolved once its value is known
    }
  }, [flagEnabled]);

  if (!isResolved) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }
  return (
    <Stack className="gap-3 lg:gap-6">
      <Stack className="relative gap-3 lg:gap-8">
        <FeatureTabs
          defaultOrderValue={'Random'}
          tabItems={getInspirationTabs({ showEmailFeeds: flagEnabled })}
        />
        <Stack className="gap-3 lg:gap-6 relative">
          <div className={'flex justify-between gap-5 items-center'}>
            <div>
              <Text weight="semibold" size={'xxl'}>
                The Best. Not The Rest.
              </Text>
              <Text
                weight="normal"
                size={'lg'}
                className="text-thememutedforeground hidden lg:flex"
              >
                Explore top-tier examples handpicked to spark your creativity.
              </Text>
            </div>
            <div className={'flex gap-2'}>
              <div className="lg:hidden">
                {
                  <OrderFilterSelect
                    defaultFilter={orderFilter || 'Random'}
                    options={[
                      'Random',
                      'Recent',
                      'Oldest',
                      'Popular',
                      'Most Requested',
                    ]}
                  />
                }
              </div>
              <Button
                size={'sm'}
                className="h-8"
                variant={'brandGradient'}
                onClick={() => {
                  if (orderFilter !== 'Random') {
                    navigate({
                      search: (old) => {
                        return {
                          ...old,
                          orderFilter: 'Random',
                        };
                      },
                      params: (old) => {
                        return {
                          ...old,
                        };
                      },
                    });

                    posthog?.capture(
                      PostHogEvents.USER_SHUFFLED_AD_INSPIRATION,
                    );
                  } else {
                    refetch();
                  }
                }}
              >
                <Shuffle className="h-5" />
                Shuffle
              </Button>
            </div>
          </div>
        </Stack>
      </Stack>

      <div
        className={`lg:sticky lg:top-0 lg:z-10 lg:bg-white lg:py-2 flex ${pageIndustry && filterOptions && filterOptions.length > 0 ? 'justify-between' : 'justify-end'} items-start gap-1.5`}
      >
        {pageIndustry && filterOptions && filterOptions.length > 0 && (
          <AdFilter
            initialOptions={filterOptions}
            onOptionsChange={handleOptionsChange}
          />
        )}

        <div className={'hidden lg:flex'}>
          <OrderFilterSelect
            defaultFilter={orderFilter || 'Random'}
            options={[
              'Random',
              'Recent',
              'Oldest',
              'Popular',
              'Most Requested',
            ]}
          />
        </div>
      </div>
      <InspirationRequestNotifier />
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Loader />
        </div>
      ) : (
        <div>
          {inspirationFeed && allData && allData.length === 0 ? (
            <div className="flex flex-col justify-center items-center">
              <p className="text-center w-4/5 lg:w-1/2 mb-5">
                Looks like you've gone down a path with no inspiration...this is
                your fault! All we do is win...but really, maybe try a different
                configuration of filters - we got you!
              </p>
              <img src="/giphy.webp" width="480" height="270" alt="" />
            </div>
          ) : (
            inspirationFeed &&
            allData &&
            allData.length > 0 && <DiscoverGridView data={allData} />
          )}
        </div>
      )}

      <div ref={scrollRef} className="flex justify-center w-full min-h-14 h-14">
        {isFetchingNextPage && <Loader />}
      </div>
    </Stack>
  );
}

export default All;
