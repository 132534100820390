import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { trpc } from "@/utils/trpc.ts";
import { useCallback, useEffect, useState } from "react";
import { ErrorDisplay } from "@/components/error.tsx";
import {
  LandingAdGridView,
} from "@/components/templates/LandingAdGridView";
import { Loader } from "@/components/custom-components/Loader";
import { Stack, Text } from "@/components/custom-components";
import { z } from "zod";
import { AirtableLandingPageRecord, orderFilter as OrderFilterTypes } from "../../../../src/shared/airtable/index.ts";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { FilterOption } from "./feeds.templates.index.tsx";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";
import TemplatesFilterPopover from "@/components/custom-components/TemplateFilterPopover";
import { Shuffle, TrendingUp } from "@mynaui/icons-react";
import { Button } from "@/components/shadcn/button.tsx";
import { AdFeedFilterOption } from "@/hooks/useFilterFeed.tsx";
import { useInView } from "react-intersection-observer";

type SearchParams = {
  cacheBuster?: number;
  loadAdsCreatedAfter?: number;
  sideBarOpen?: boolean;
  categories?: string;
  orderFilter?: z.infer<typeof OrderFilterTypes>;
};

export type SelectedTemplateFilters = {
  categories?: string[];
};

export const Route = createFileRoute("/feeds/templates/landing-pages/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const cacheBuster = (search?.cacheBuster as number) ?? Math.random();
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterTypes>
      | undefined;

    return {
      cacheBuster: cacheBuster,
      sideBarOpen,
      orderFilter,
    };
  },
});

function All() {
  const {
    cacheBuster,
    orderFilter,
    categories: queryCategories,
  } = Route.useSearch();
  const navigate = useNavigate();

  const { data: newlyAddedTemplateCount } =
    trpc.getNewlyAddedTemplatesCount.useQuery(
      { templatesType: "landing-page", daysToCheck: 30 },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    );

  // Get the template collections to be passed to the filter
  const { data: landingCategories } =
    trpc.getLandingPageTemplateCategories.useQuery();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedTemplateFilters>({
      categories: queryCategories ? queryCategories.split(",") : undefined,
    });

  const updateQueryString = useCallback(
    (params: { categories?: string }) => {
      navigate({
        to: "/feeds/templates/landing-pages",
        replace: true,
        search: (old) => {
          const newSearch = {
            ...old,
            ...params,
            orderFilter,
            cacheBuster: Math.random(),
          };
          return JSON.stringify(newSearch) === JSON.stringify(old)
            ? old
            : newSearch; // Prevent redundant updates
        },
      });
    },
    [navigate, orderFilter],
  );

  const handleOptionsChange = useCallback(
    (options: AdFeedFilterOption[]) => {
      const selectedCategories: string[] = [];

      options.forEach((group) => {
        group.optionItems.forEach((item) => {
          if (item.value) {
            if (group.title === "Category") selectedCategories.push(item.label);
          }
        });
      });

      setSelectedFilters({
        categories:
          selectedCategories.length > 0 ? selectedCategories : undefined,
      });

      updateQueryString({
        categories:
          selectedCategories.length > 0
            ? selectedCategories.join(",")
            : undefined,
      });
    },
    [updateQueryString],
  );

  // Update AdFilter options based on selectedFilters
  useEffect(() => {
    if (landingCategories && filterOptions.length === 0) {
      setCursor(0);
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Category",
            counter: 0,
            optionItems: landingCategories.map((i) => ({
              label: i.category,
              value: false,
            })),
          },
        ];
      });
    }
  }, [landingCategories, filterOptions.length]);

  useEffect(() => {
    if (landingCategories && filterOptions.length === 0) {
      setFilterOptions((prevOptions) => {
        if (prevOptions.length > 0) return prevOptions; // Prevent redundant updates
        return [
          {
            title: "Category",
            counter: 0,
            optionItems: landingCategories.map((i) => ({
              label: i.category,
              value: false,
            })),
          },
        ];
      });
    }
  }, [landingCategories, filterOptions.length]);


  const [cursor, setCursor] = useState(0);
  const [allData, setAllData] = useState<AirtableLandingPageRecord[] | undefined>(
    undefined
  );
  const {
    data: landingPageTemplates,
    fetchNextPage,
    refetch,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
  } = trpc.landingPages.useInfiniteQuery(
    {
      categories: selectedFilters.categories,
      sortingOptions: orderFilter,
      cacheBuster: cacheBuster,
      limit: 20,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialCursor: cursor,
    }
  );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (
      inView &&
      hasNextPage &&
      !isFetchingNextPage &&
      landingPageTemplates &&
      allData &&
      allData.length &&
      landingPageTemplates.pages[landingPageTemplates.pages.length - 1].LandingPages.length >= 20
    ) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, landingPageTemplates]);

  useEffect(() => {
    if (!landingPageTemplates) return;

    setAllData(() => undefined);

    const records: AirtableLandingPageRecord[] = [];
    for (const page of landingPageTemplates.pages) {
      records.push(...page.LandingPages);
    }
    setAllData(() =>
      records.filter(
        (item, index) =>
          index ===
          records.findIndex((obj) => obj["LP ID"] === item["LP ID"])
      )
    );
  }, [landingPageTemplates]);

  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});


  useEffect(() => {
    if (
      !isLoadingPermission &&
      permissionData?.userCanAccessEverything === false &&
      !upgradeDialogOpen
    ) {
      setUpgradeDialogOpen(true); // Only update if `upgradeDialogOpen` is false
    }
  }, [
    permissionData?.userCanAccessEverything,
    isLoadingPermission,
    upgradeDialogOpen,
  ]);

  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
            <Stack className="gap-3 lg:gap-6 relative">
              <Stack className="gap-3 lg:gap-8">
                <FeatureTabs
                  tabItems={[
                    {
                      name: "Ads",
                      link: "/feeds/templates",
                    },
                    {
                      name: "Emails",
                      link: "/feeds/templates/emails",
                      isPremiumFeature: true,
                    },
                    {
                      name: "Landers",
                      link: "/feeds/templates/landing-pages",
                      isPremiumFeature: true,
                    },
                  ]}
                />
                <Stack className="gap-3 lg:gap-6">
                  <div className={"flex justify-between gap-5 items-center"}>
                    <div>
                      <Text weight="semibold" size={"xxl"} className="w-fit">
                        Yours. For The Making.
                      </Text>
                      <Text
                        weight="normal"
                        size={"lg"}
                        className="text-thememutedforeground hidden lg:flex"
                      >
                        Shape ideas into standout ads with endless possibilities.
                      </Text>
                    </div>
                    <div className={"flex gap-2 items-center flex-wrap justify-end"}>
                      {(newlyAddedTemplateCount ?? 0) > 0 && (
                        <div className="flex gap-2 w-fit items-center">
                          <TrendingUp className="text-themedestructive" />
                          <Text
                            size={"sm"}
                            weight={"medium"}
                            className="text-thememutedforeground "
                          >
                            {newlyAddedTemplateCount} New{" "}
                            {newlyAddedTemplateCount == 1 ? "Template" : "Templates"}
                          </Text>
                        </div>
                      )}
      
                      <div className="lg:hidden">
                        <OrderFilterSelect
                          defaultFilter={orderFilter || "Random"}
                          options={["Random", "Recent", "Popular", "Oldest"]}
                        />
                      </div>
                      <Button
                        size={"sm"}
                        variant={"brandGradient"}
                        className="h-8"
                        onClick={() => {
                          if (orderFilter !== "Random") {
                            navigate({
                              search: (old) => {
                                return {
                                  ...old,
                                  orderFilter: "Random",
                                };
                              },
                              params: (old) => {
                                return {
                                  ...old,
                                };
                              },
                            });
                          } else {
                            refetch();
                          }
                        }}
                      >
                        <Shuffle className="h-5" />
                        Shuffle
                      </Button>
                    </div>
                  </div>
                </Stack>
              </Stack>
      
              <div
                className={`z-10 lg:sticky lg:top-0 lg:bg-white lg:py-2 flex ${filterOptions && filterOptions.length > 0 ? "justify-between" : "justify-end"} items-center`}
              >
                <div>
                  {filterOptions && filterOptions.length > 0 && (
                    <TemplatesFilterPopover
                      initialOptions={filterOptions}
                      onOptionsChange={handleOptionsChange}
                      placeholder={"Filter Ad Templates"}
                    />
                  )}
                </div>
      
                <div className={"hidden lg:flex"}>
                  <OrderFilterSelect
                    defaultFilter={orderFilter || "Random"}
                    options={["Random", "Recent", "Popular", "Oldest"]}
                  />
                </div>
              </div>
              {isLoading ? (
                <div className="flex justify-center items-center w-full h-[70vh]">
                  <Loader />
                </div>
              ) : isError ? (
                <ErrorDisplay />
              ) : (
                <LandingAdGridView
                  Cards={(allData ?? []).map((data) => ({
                    created: data.Created,
                    ID: data["LP ID"].toString(),
                    Screenshot: data["Landing Page Screenshot"],
                    type: "landing-page",
                    category: data.Category,
                    isSaved: data.isSaved,
                    imageUrl: data.imageUrl,
                    brandName: data.brandName,
                    brandImage: data.brandImage,
                    brandId: data.brandId
                    
                  }))}
                  HasAccess={
                    landingPageTemplates && landingPageTemplates.pages.length > 0
                      ? landingPageTemplates.pages[0].HasAccess
                      : false
                  }
                  type={"landing-page"}
                />
              )}
              <div
                ref={scrollRef}
                className="flex justify-center w-full min-h-14 h-14"
              >
                {isFetchingNextPage && <Loader />}
              </div>
            </Stack>
    </>
  );
}
