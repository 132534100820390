import { Button } from "@/components/shadcn/button";
import { Stack } from "../Layout";
import { Text } from "../Text";
import { trpc } from "@/utils/trpc";
import { PRO_PLAN } from "@/utils/data/plans";
import { useState } from "react";
import FreeTrialEnding from "@/components/FreeTrialEnding";

export function InspirationRequestNotifier() {
  const { data: permissionData } = trpc.permissions.useQuery(null, {});
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const userCanMakeRequests =
    !(permissionData?.hasRequestLimitReached ?? true) ||
    (permissionData?.limitLeft ?? 0) > 0;

  const isProUser = permissionData
    ? permissionData.usersPlans.includes(PRO_PLAN.plan_id) ||
      permissionData.usersPlans.includes(PRO_PLAN.no_trial_plan_id)
    : false;
  return permissionData ? (
    permissionData.limitLeft < 3 ? (
      <div
        className={`w-full px-4 py-2 flex gap-3 rounded-lg ${isProUser && !userCanMakeRequests ? "bg-thememuted" : "bg-purple-50"} justify-between items-center`}
      >
        {upgradeDialogOpen && permissionData && (
          <FreeTrialEnding
            open={upgradeDialogOpen}
            onOpenChange={() => setUpgradeDialogOpen(false)}
            permissionData={permissionData}
          />
        )}
        <Stack className="gap-1">
          <Text weight={"semibold"} size={"sm"}>
            {userCanMakeRequests
              ? `⚡ Only ${permissionData.limitLeft} template ${permissionData.limitLeft > 1 ? "requests" : "request"} left! `
              : `🚫 You’ve reached your limit! `}

            <Text weight={"normal"} size={"sm"}>
              {userCanMakeRequests
                ? `${isProUser ? `` : `Upgrade to Pro for 15 requests per month and unleash your creativity!`}`
                : ` You’ve used all ${permissionData.requestLimit} template requests this month. ${
                    isProUser
                      ? `Don’t worry—your limit resets on ${new Date(
                          permissionData.limitNextUpdateAt
                        ).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}.`
                      : `Upgrade to Pro for 15 requests a month!`
                  }`}
            </Text>
          </Text>
          <Text
            weight={"thin"}
            size={"xs"}
            className="text-thememutedforeground"
          >
            {userCanMakeRequests
              ? `Your limit of ${permissionData.requestLimit} requests per month resets on ${new Date(
                  permissionData.limitNextUpdateAt
                ).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}.`
              : isProUser
                ? ``
                : `Don’t worry—your limit of 5 requests per month resets on ${new Date(
                    permissionData.limitNextUpdateAt
                  ).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}.`}
          </Text>
        </Stack>

        {isProUser ? null : (
          <Button variant={"destructivePurple"} size={"sm"}>
            Upgrade
          </Button>
        )}
      </div>
    ) : null
  ) : null;
}
