import { forwardRef, useState, FormEvent, useEffect } from 'react';
import { Trash, X } from '@mynaui/icons-react';
import { SelectedTemplateFilters } from '@/routes/feeds.templates.index.tsx';
import { ChevronUpDown } from '@mynaui/icons-react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/shadcn/dropdown-menu';
import { Check } from '@mynaui/icons-react';
import { Badge } from '@/components/shadcn/badge';
import { Button } from '@/components/shadcn/button';
import { ScrollArea, ScrollBar } from '@/components/shadcn/scroll-area';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/shadcn/command';
import {
  AdFeedFilterOption,
  AdSelectedFilters,
} from '@/hooks/useFilterFeed.tsx';
import { cn } from '@/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/shadcn/tooltip';

type OptionItem = {
  label: string;
  value: boolean;
};

export type AccordionData = {
  title: string;
  counter: number;
  optionItems: OptionItem[];
};

type TemplatesFilterPopoverProps = {
  className?: string;
  initialOptions: AdFeedFilterOption[];
  onOptionsChange?: (options: AdFeedFilterOption[]) => void;
  placeholder: string;
};

export const TemplatesFilterPopover = forwardRef<
  HTMLButtonElement,
  TemplatesFilterPopoverProps
>(({ initialOptions, onOptionsChange }, ref) => {
  const [options, setOptions] = useState<AdFeedFilterOption[]>(initialOptions);

  useEffect(() => {
    if (onOptionsChange) onOptionsChange(options);
  }, [options, onOptionsChange]);

  const handleCheckboxChange = (
    accordionIndex: number,
    optionIndex: number,
    checked: boolean | FormEvent<HTMLButtonElement>,
  ) => {
    let isChecked: boolean;

    if (typeof checked === 'boolean') {
      isChecked = checked;
    } else {
      isChecked = checked.currentTarget.getAttribute('aria-checked') === 'true';
      isChecked = !isChecked;
    }

    setOptions((prevOptions) =>
      prevOptions.map((group, index) => {
        if (index === accordionIndex) {
          const newOptionItems = group.optionItems.map((item, idx) => {
            if (idx === optionIndex) {
              return { ...item, value: isChecked };
            }
            return item;
          });
          return { ...group, optionItems: newOptionItems };
        }
        return group;
      }),
    );
  };

  const handleRemoveFilter = (filterType: string, filterValue: string) => {
    setOptions((prevOptions) =>
      prevOptions.map((group) => {
        if (group.title.toLowerCase() === filterType) {
          const newOptionItems = group.optionItems.map((item) => {
            if (item.label === filterValue) {
              return { ...item, value: false };
            }
            return item;
          });
          return { ...group, optionItems: newOptionItems };
        }
        return group;
      }),
    );
  };

  const handleClearPopoverFilters = (accordionIndex: number) => {
    setOptions((prevOptions) =>
      prevOptions.map((group, index) => {
        if (index === accordionIndex) {
          return {
            ...group,
            optionItems: group.optionItems.map((item) => ({
              ...item,
              value: false,
            })),
          };
        }
        return group;
      }),
    );
  };

  const handleClearFilters = () => {
    setOptions((prevOptions) =>
      prevOptions.map((group) => ({
        ...group,
        optionItems: group.optionItems.map((item) => ({
          ...item,
          value: false,
        })),
      })),
    );
  };

  const selectedFilters = options.reduce(
    (acc, group) => {
      const selectedItems = group.optionItems.filter((item) => item.value);
      if (selectedItems.length > 0) {
        acc[group.title.toLowerCase()] = selectedItems.map(
          (item) => item.label,
        );
      }
      return acc;
    },
    {} as Record<string, string[]>,
  );

  const top5Collections =
    options.length > 0 ? options[0].optionItems.slice(0, 5) : [];

  return (
    <>
      <div className="hidden lg:flex gap-2 items-center flex-wrap">
        {...SelectedFiltersBadges({
          selectedFilters,
          onRemoveFilter: handleRemoveFilter,
          onClearFilter: handleClearFilters,
          top5Filters: top5Collections.map((item) => item.label),
        })}
        {top5Collections.map((option, idx) =>
          option.value ? (
            <Badge
              key={idx}
              className="gap-2 bg-purple-100 items-center hover:bg-purple-100"
            >
              <span
                className={
                  'text-sm text-nowrap text-themedestructive font-medium leading-5'
                }
              >
                {option.label
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </span>
              <button
                onClick={() => handleCheckboxChange(0, idx, !option.value)}
              >
                <X className="text-themedestructive w-3 h-3" />
              </button>
            </Badge>
          ) : (
            <Button
              key={idx}
              variant={'outline'}
              className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
              onClick={() => handleCheckboxChange(0, idx, !option.value)}
            >
              {option.label
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Button>
          ),
        )}
        {options.map((item, index) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {item.title == 'Collection' ? (
                item.optionItems.slice(5).length > 0 ? (
                  <Button
                    ref={ref}
                    variant={'outline'}
                    className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                  >
                    <ChevronUpDown className="h-5 w-4" />
                    More
                  </Button>
                ) : null
              ) : (
                <Button
                  ref={ref}
                  variant={'outline'}
                  className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                >
                  <ChevronUpDown className="h-5 w-4" />
                  {item.title}
                </Button>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className={`${item.title == 'Brand' ? 'p-0' : ''}  border-none grid grid-cols-1 max-h-96 overflow-y-auto`}
            >
              {item.title == 'Brand' ? (
                <Command>
                  <div className="flex gap-2 items-center justify-between pr-3">
                    <CommandInput
                      placeholder="Search Brands..."
                      className="h-9"
                    />
                    <Tooltip>
                      <TooltipTrigger>
                        <span
                          onClick={() =>
                            handleClearPopoverFilters(options.indexOf(item))
                          }
                        >
                          <Trash
                            className={
                              'h-5 w-5 text-thememutedforeground cursor-pointer'
                            }
                          />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-sm">
                        <p>Clear Filter</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <CommandList>
                    <CommandEmpty>No brand found.</CommandEmpty>
                    <CommandGroup>
                      {item.optionItems.map((option, idx) => (
                        <CommandItem
                          key={idx}
                          value={option.label}
                          onSelect={() =>
                            handleCheckboxChange(
                              index,
                              idx +
                                (item.title == 'Collection'
                                  ? top5Collections.length
                                  : 0),
                              !option.value,
                            )
                          }
                        >
                          {option.logo ? (
                            <img
                              src={option.logo ?? '/bg_gradient.jpg'}
                              className="min-w-6 w-6 h-6 rounded-full"
                            />
                          ) : null}
                          {option.label}
                          <Check
                            className={cn(
                              'ml-auto h-4 w-4',
                              option.value ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              ) : (
                <div>
                  <DropdownMenuLabel
                    className={'flex gap-3 items-center justify-between'}
                  >
                    <span className={'text-sm font-semibold'}>
                      {item.title}
                    </span>
                    <Tooltip>
                      <TooltipTrigger>
                        <span onClick={() => handleClearPopoverFilters(index)}>
                          <Trash
                            className={
                              'h-5 w-5 text-thememutedforeground cursor-pointer'
                            }
                          />
                        </span>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-sm">
                        <p>Clear Filter</p>
                      </TooltipContent>
                    </Tooltip>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup
                    key={item.title}
                    className=" grid grid-cols-2"
                  >
                    {(item.title == 'Collection'
                      ? item.optionItems.slice(5)
                      : item.optionItems
                    ).map((option, idx) => (
                      <DropdownMenuItem
                        key={idx}
                        onClick={() =>
                          handleCheckboxChange(
                            index,
                            idx +
                              (item.title == 'Collection'
                                ? top5Collections.length
                                : 0),
                            !option.value,
                          )
                        }
                        className="flex items-center gap-2"
                      >
                        <div className="w-4 h-4">
                          {option.value && <Check className="w-4 h-4" />}
                        </div>
                        {option.logo && (
                          <img
                            className="w-5 h-5 rounded-full"
                            src={option.logo}
                          />
                        )}
                        <div className="max-w-52 w-52">
                          {option.label
                            .split('_')
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                            )
                            .join(' ')}
                        </div>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuGroup>
                </div>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        ))}
      </div>
      <ScrollArea
        type="always"
        className="lg:hidden flex  w-[calc(100vw-24px)] flex-1"
      >
        <div className="flex gap-2 items-center w-full">
          {...SelectedFiltersBadges({
            selectedFilters,
            onRemoveFilter: handleRemoveFilter,
            onClearFilter: handleClearFilters,
            top5Filters: top5Collections.map((item) => item.label),
          })}
          {top5Collections.map((option, idx) =>
            option.value ? (
              <Badge
                key={idx}
                className="gap-2 bg-purple-100 items-center hover:bg-purple-100"
              >
                <span
                  className={
                    'text-sm text-nowrap text-themedestructive font-medium leading-5'
                  }
                >
                  {option.label
                    .split('_')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </span>
                <button
                  onClick={() => handleCheckboxChange(0, idx, !option.value)}
                >
                  <X className="text-themedestructive w-3 h-3" />
                </button>
              </Badge>
            ) : (
              <Button
                key={idx}
                variant={'outline'}
                className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                onClick={() => handleCheckboxChange(0, idx, !option.value)}
              >
                {option.label
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Button>
            ),
          )}
          {options.map((item, index) => (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                {item.title == 'Collection' ? (
                  item.optionItems.slice(5).length > 0 ? (
                    <Button
                      ref={ref}
                      variant={'outline'}
                      className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                    >
                      <ChevronUpDown className="h-5 w-4" />
                      More
                    </Button>
                  ) : null
                ) : (
                  <Button
                    ref={ref}
                    variant={'outline'}
                    className={`flex items-center gap-1 rounded-full px-3.5 !h-7`}
                  >
                    <ChevronUpDown className="h-5 w-4" />
                    {item.title}
                  </Button>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className={`${item.title == 'Brand' ? 'p-0' : ''} border-none grid grid-cols-1 max-h-96 overflow-y-auto`}
              >
                {item.title == 'Brand' ? (
                  <Command>
                    <CommandInput
                      placeholder="Search Brands..."
                      className="h-9"
                    />
                    <CommandList>
                      <CommandEmpty>No brand found.</CommandEmpty>
                      <CommandGroup>
                        {item.optionItems.map((option, idx) => (
                          <CommandItem
                            key={idx}
                            value={option.label}
                            onSelect={() =>
                              handleCheckboxChange(
                                index,
                                idx +
                                  (item.title == 'Collection'
                                    ? top5Collections.length
                                    : 0),
                                !option.value,
                              )
                            }
                          >
                            {option.logo ? (
                              <img
                                src={option.logo ?? '/bg_gradient.jpg'}
                                className="min-w-6 w-6 h-6 rounded-full"
                              />
                            ) : null}
                            {option.label}
                            <Check
                              className={cn(
                                'ml-auto h-4 w-4',
                                option.value ? 'opacity-100' : 'opacity-0',
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                ) : (
                  <div>
                    <DropdownMenuLabel
                      className={'flex gap-3 items-center justify-between'}
                    >
                      <span className={'text-sm font-semibold'}>
                        {item.title}
                      </span>
                      <Tooltip>
                        <TooltipTrigger>
                          <span
                            onClick={() => handleClearPopoverFilters(index)}
                          >
                            <Trash
                              className={
                                'h-5 w-5 text-thememutedforeground cursor-pointer'
                              }
                            />
                          </span>
                        </TooltipTrigger>
                        <TooltipContent className="max-w-sm">
                          <p>Clear Filter</p>
                        </TooltipContent>
                      </Tooltip>
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup
                      key={item.title}
                      className=" grid lg:grid-cols-2"
                    >
                      {(item.title == 'Collection'
                        ? item.optionItems.slice(5)
                        : item.optionItems
                      ).map((option, idx) => (
                        <DropdownMenuItem
                          key={idx}
                          onClick={() =>
                            handleCheckboxChange(
                              index,
                              idx +
                                (item.title == 'Collection'
                                  ? top5Collections.length
                                  : 0),
                              !option.value,
                            )
                          }
                          className="flex items-center gap-2"
                        >
                          <div className="w-4 h-4">
                            {option.value && <Check className="w-4 h-4" />}
                          </div>
                          {option.logo && (
                            <img
                              className="w-5 h-5 rounded-full"
                              src={option.logo}
                            />
                          )}
                          <div className="max-w-52 w-52">
                            {option.label
                              .split('_')
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1),
                              )
                              .join(' ')}
                          </div>
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuGroup>
                  </div>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          ))}
        </div>
        <ScrollBar orientation="horizontal" className="w-full hidden" />
      </ScrollArea>
    </>
  );
});

export default TemplatesFilterPopover;

type SelectedFiltersBadgesProps = {
  selectedFilters: AdSelectedFilters | SelectedTemplateFilters;
  onRemoveFilter: (filterType: string, filterValue: string) => void;
  onClearFilter: () => void;
  top5Filters: string[];
};

const SelectedFiltersBadges = ({
  selectedFilters,
  onRemoveFilter,
  top5Filters,
}: SelectedFiltersBadgesProps) => {
  const renderBadges = (filters: string[], filterType: string) => {
    return filters
      .filter((filterItem) => !top5Filters.includes(filterItem))
      .map((filter, index) => (
        <Badge
          key={index}
          className="gap-2 bg-purple-100 items-center hover:bg-purple-100 "
        >
          <span
            className={
              'text-sm text-nowrap text-themedestructive font-medium leading-5'
            }
          >
            {filter
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </span>
          <button onClick={() => onRemoveFilter(filterType, filter)}>
            <X className="text-themedestructive w-3 h-3" />
          </button>
        </Badge>
      ));
  };
  return Object.entries(selectedFilters).map(([filterType, filters]) =>
    renderBadges(filters, filterType),
  );
};
