export function PingCirclesRed() {
  return (
    <div className="relative flex h-[5px] w-[5px] ml-[-0.35rem] mb-4">
      <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></div>
      <div className="relative flex justify-center items-center text-white rounded-full h-[5px] w-[5px] bg-red-500" />
    </div>
  );
}
export function PingCirclesGreen() {
  return (
    <div className="relative flex h-[8px] w-[8px]">
      <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></div>
      <div className="relative flex justify-center items-center text-white rounded-full h-[8px] w-[8px] bg-green-500" />
    </div>
  );
}
export function PingCirclesGray() {
  return (
    <div className="relative flex h-[8px] w-[8px]">
      <div className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-500 opacity-75"></div>
      <div className="relative flex justify-center items-center text-white rounded-full h-[8px] w-[8px] bg-gray-500" />
    </div>
  );
}
