export const REQUEST_TEMPLATE_MESSAGE = "Hold on a moment...";
export const REQUEST_TEMPLATE_DESCRIPTION =
  "We’re currently checking your request status. This may take a few seconds.";

export enum ExpertSpeciality {
  ad = "Ad Collection",
  email = "Email Collection",
}

export type BoardCollectionDataProps = {
  collections: ExpertProductType[];
};

export type ExpertProductType = {
  title: string;
  description: string;
  url: string;
  ctaButton: string;
  priceID: string;
  planID: string[];
  imageLink: string;
  speciality: ExpertSpeciality;
};

export const ExpertProducts: ExpertProductType[] = [
  {
    title: "Fraser Cottrell",
    url: "/feeds/collections/experts/ad/Fraser%20Cottrell",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-fraser.png",
    speciality: ExpertSpeciality.ad,
  },
  {
    title: "Tom Brown",
    url: "/feeds/collections/experts/ad/Tom%20Brown",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expert-tom.png",
  },
  {
    title: "Rahul Issar",
    url: "/feeds/collections/experts/ad/Rahul%20Issar",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expert-rahul.png",
  },
  {
    title: "Zach Duncan",
    url: "/feeds/collections/experts/ad/Zach%20Duncan",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expert-zach.png",
  },
  {
    title: "Austin Rabin",
    url: "/feeds/collections/experts/ad/Austin%20Rabin",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expoert-austin.png",
  },
  {
    title: "Rabah Rahil",
    url: "/feeds/collections/experts/ad/Rabah%20Rahil",
    description:
      "Spent millions of dollars at an agency, ran his own agency and most recently became CMO at Triple Whale.",
    ctaButton: "Get Rabah's Creatives",
    priceID: "prc_rabah-s-pack-mr2r0nth",
    planID: [
      "pln_rabah-s-pack-cp2q0nb6",
      "pln_rabah-s-creative-pack-obh70opq",
      "pln_rabah-legacy-pack-15fb0o8e",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expert-rabah-r.png",
  },
  {
    title: "Nick Shackelford",
    url: "/feeds/collections/experts/ad/Nick%20Shackelford",
    description:
      "A pioneer in Facebook Ads for DTC brands. Co-founder of: Structured, Konstant Kreative & Brez.",
    ctaButton: "Get Nick's Creatives",
    priceID: "prc_nick-s-pack-s31n0nzl",
    planID: [
      "pln_nick-s-pack-lu1m0npx",
      "pln_nick-s-creative-pack-s6vn0n68",
      "pln_nick-s-pack-legacy-8ztv0nhz",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expert-nick-s.png",
  },
  {
    title: "Ash Melwani",
    url: "/feeds/collections/experts/ad/Ash%20Melwani",
    description:
      "Co-Founder & CMO of Obvi, one of the world’s fastest growing health & nutrition brands.",
    ctaButton: "Get Ash's Creatives",
    priceID: "prc_ash-s-pack-sk2f0nrf",
    planID: [
      "pln_ash-s-pack-i41d01o8",
      "pln_ash-legacy-pack-gef30o1t",
      "pln_ash-s-creative-pack-gsh50oot",
      "",
    ],
    speciality: ExpertSpeciality.ad,
    imageLink: "/expert-ash-m.png",
  },
  {
    title: "Barry Hott",
    url: "/feeds/collections/experts/ad/Barry%20Hott",
    description:
      "Barry is a Growth Marketing Consultant & Advisor know for his expertise in growth and creative strategies.",
    ctaButton: "Get Barry's Creatives",
    priceID: "prc_barry-s-creative-pack-lkx40153",
    planID: [
      "pln_barry-s-pack-rd2w0ni3",
      "pln_barry-hot-expert-pack-u26u0hn0",
      "pln_barry-legacy-pack-yyf20our",
    ],
    imageLink: "/expert-barry-h.png",
    speciality: ExpertSpeciality.ad,
  },
  {
    title: "Sarah Levinger",
    url: "/feeds/collections/experts/ad/Sarah%20Levinger",
    description:
      "Sarah is a Forbes-featured consumer behavior analyst, creative strategist, and performance creative consultant.",
    ctaButton: "Get Sarah's Creatives",
    priceID: "prc_sara-s-creative-pack-hyvl0ns4",
    planID: [
      "pln_sarah-s-creative-pack-puvk0nz1",
      "pln_sarah-legacy-pack-ntu60nuz",
      "pln_sarah-s-pack-mv1601hd",
    ],
    imageLink: "/expert-sarah-l.png",
    speciality: ExpertSpeciality.ad,
  },
  {
    title: "Dara Denney",
    url: "/feeds/collections/experts/ad/Dara%20Denney",
    description:
      "Dara is a performance creative consultant and creator. She’s led creative and growth teams for the last 5 years.",
    ctaButton: "Get Dara's Creatives",
    priceID: "prc_dara-s-pack-kp1l01dv",
    planID: [
      "pln_dara-s-pack-hd1k015b",
      "pln_dara-legacy-pack-8gf40oh3",
      "pln_dara-s-creative-pack-4wvp0ncz",
    ],
    imageLink: "/expert-dara-d.png",
    speciality: ExpertSpeciality.ad,
  },
  {
    title: "Alexa Kilroy",
    url: "/feeds/collections/experts/ad/Alexa%20Kilroy",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    imageLink: "/expert-alexa.jpg",
    speciality: ExpertSpeciality.ad,
  },
  {
    title: "Feras Khouri",
    url: "/feeds/collections/experts/ad/Feras%20Khouri",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.email,
    imageLink: "/expert-feras.jpg",
  },
  {
    title: "Jess Chan",
    url: "/feeds/collections/experts/ad/Jess%20Chan",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.email,
    imageLink: "/expert-jess.jpg",
  },
  {
    title: "Eric Rausch",
    url: "/feeds/collections/experts/ad/Eric%20Rausch",
    description:
      "Austin has helped launch many brands and helped them validate product market fit with his proven templates",
    ctaButton: "Get Austin's Creatives",
    priceID: "prc_austin-rabin-pack-rm7m0hkb",
    planID: [
      "pln_austin-rabin-pack-yh7l0h6c",
      "pln_austin-rabin-pack-kv520sy9",
    ],
    speciality: ExpertSpeciality.email,
    imageLink: "/expert-eric.jpg",
  },
];
