import { OrderFilterSelect } from "@/components/ad-inspiration/OrderFilterSelect.tsx";
import { Stack, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import { ErrorDisplay } from "@/components/error.tsx";
import { Badge } from "@/components/shadcn/badge.tsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/shadcn/breadcrumb.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import showToastNotification from "@/hooks/useShowToast.tsx";
import { trpc } from "@/utils/trpc.ts";
import {
  BrandInstagram,
  BrandLinkedin,
  BrandX,
  Link as LinkIcon,
} from "@mynaui/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { useMemo } from "react";
import { z } from "zod";
import {
  Expert,
  orderFilter as OrderFilterType,
} from "../../../../src/shared/airtable/types.ts";
import { Link } from "@tanstack/react-router";
import { ExpertProducts } from "@/_shared/constants.ts";
import {
  GenericCardProps,
  LandingAdGridView,
} from "@/components/templates/LandingAdGridView";
import { FeatureTabs } from "@/components/custom-components/FeatureTabs/index.tsx";
type SearchParams = {
  sideBarOpen?: boolean;
  orderFilter?: z.infer<typeof OrderFilterType>;
};

export const Route = createFileRoute(
  "/feeds/collections/experts/email/$expert"
)({
  component: EmailExpertCollectionTemplates,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;
    const orderFilter = search?.orderFilter as
      | z.infer<typeof OrderFilterType>
      | undefined;

    return {
      sideBarOpen,
      orderFilter,
    };
  },
});

function EmailExpertCollectionTemplates() {
  const { orderFilter } = Route.useSearch();

  const { expert } = Route.useParams();
  const [, copyToClipboard] = useCopyToClipboard();

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const {
    data: emailTemplates,
    isLoading,
    error,
  } = trpc.emails.useQuery(
    {
      Expert: expert as Expert,
      sortingOptions: orderFilter,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const formatData = useMemo((): GenericCardProps[] => {
    if (!emailTemplates) return [];
    const returnData = [] as GenericCardProps[];
    emailTemplates.Emails?.map((datum) => {
      returnData.push({
        Screenshot: datum["Email Screenshot"],
        ID: datum["Email ID"].toString(),
        created: datum.Created,
        type: "email",
        category: datum.Category,
        isSaved: datum.isSaved,
        imageUrl: datum.imageUrl,
      });
    });
    return returnData;
  }, [emailTemplates]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }
  const OrderFilterRow = ({ className }: { className: string }) => (
    <div className={className}>
      <Text
        className="text-thememutedforeground w-fit text-nowrap"
        size={"base"}
        weight={"normal"}
      >
        {formatData.length} {formatData.length > 1 ? " Ads" : " Ad"}
      </Text>
      <Button
        onClick={() => {
          copyToClipboard(
            `${window.location.origin}/feeds/collections/experts/email/${expert}`
          );
          showToastNotification("success", { message: "Copied!" });
        }}
        className="lg:hidden flex"
        variant={"outline"}
        size={"iconSm"}
      >
        <LinkIcon className="w-5 h-5" />
      </Button>

      <OrderFilterSelect
        defaultFilter={orderFilter || "Random"}
        options={["Random", "Recent", "Popular", "Oldest"]}
      />
    </div>
  );

  return (
    <Stack className="gap-3 lg:gap-8">
      <FeatureTabs
        defaultOrderValue={"Random"}
        urlSegmentCountToCompare={4}
        tabItems={[
          {
            name: "Ads",
            link:`/feeds/collections/experts/ad/${expert}/`,
          },
          {
            name: "Emails",
            link:`/feeds/collections/experts/email/${expert}/`,
          },
        ]}
      />
      <Stack className="gap-3 lg:gap-2">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={"/feeds/collections/experts"}>Collections</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Expert</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className={"flex justify-between gap-6 items-center"}>
          <div className="flex gap-2 items-center">
            <img
              className={"rounded-full w-9 h-9 bg-themeaccent"}
              src={
                ExpertProducts &&
                ExpertProducts.find((i) => i.title == expert)?.imageLink
              }
              alt={"CreativeOs"}
            />
            <Text weight="semibold" size={"xxl"}>
              {expert}
            </Text>
          </div>
          <Button
            onClick={() => {
              copyToClipboard(
                `${window.location.origin}/feeds/collections/experts/email/${expert}`
              );
              showToastNotification("success", { message: "Copied!" });
            }}
            className={"flex"}
            variant={"outline"}
            size={"iconSm"}
          >
            <LinkIcon />
          </Button>
        </div>
        <div className={`flex gap-1 items-center justify-between`}>
          <div className={`flex gap-4 items-center`}>
            <div className={`flex gap-1.5 items-center`}>
              <Text
                className="text-thememutedforeground w-fit text-nowrap"
                size={"base"}
                weight={"normal"}
              >
                Templates Created by
              </Text>
              <Text
                className="text-themeforeground w-fit text-nowrap"
                size={"base"}
                weight={"normal"}
              >
                {expert}
              </Text>
              <Badge variant={"destructivePurple"}>Expert</Badge>
            </div>
            <div className={`flex gap-1.5 items-center`}>
              <Button
                className="hidden lg:flex"
                variant={"outline"}
                size={"iconXSm"}
              >
                <BrandInstagram className="w-5 h-5" />
              </Button>
              <Button
                className="hidden lg:flex"
                variant={"outline"}
                size={"iconXSm"}
              >
                <BrandX className="w-5 h-5" />
              </Button>
              <Button
                className="hidden lg:flex"
                variant={"outline"}
                size={"iconXSm"}
              >
                <BrandLinkedin className="w-5 h-5" />
              </Button>
            </div>
          </div>
          <OrderFilterRow className="items-center gap-3 hidden lg:flex w-fit" />
        </div>
        <OrderFilterRow className="items-center gap-3 lg:hidden flex w-fit" />
      </Stack>
      <div className={"relative w-full pb-10"}>
        {(!isLoadingPermission &&
          permissionData?.userCanAccessEverything &&
          formatData) ?  (
            <>
              {formatData.length === 0 ? (
                <div className={"lg:h-96 flex justify-center items-center"}>
                  <p>No templates added to this collection</p>
                </div>
              ) : (
                <LandingAdGridView
                  Cards={formatData}
                  HasAccess={emailTemplates ? emailTemplates.HasAccess : false}
                  type={"email"}
                />
              )}
            </>
          ): null}
      </div>
    </Stack>
  );
}
