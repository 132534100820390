import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import {
  X,
} from "@mynaui/icons-react";
import { useState } from "react";
import {
  LandingFeedProps,
} from "@/components/templates/LandingAdCard";
import { trpc } from "@/utils/trpc.ts";
import FreeAccessPrompt from "@/components/FreeAccessPrompt.tsx";
import { ScrollArea } from "@/components/shadcn/scroll-area.tsx";
import { Loader } from "./custom-components/Loader";
import { LanderInspirationDetailsMedia } from "./LanderInspirationDetailsMedia";
import LanderInspirationDetailsBody from "./LanderInspirationDetailsBody";


export default function LanderAdInspirationDetails({
  open,
  onOpenChange,
  data,
  IsPublic,
}: {
  open: boolean;
  onOpenChange: () => void;
  data: LandingFeedProps;
  IsPublic: boolean;
}) {
  const [accessPromptDialogOpen, setAccessPromptDialogOpen] =
    useState<boolean>(false);
  const [saveOpen, setSaveOpen] = useState<boolean>(false);

  const { data: permissions } = trpc.permissions.useQuery(null, {});

  const {
    data: landerAdDetails,
    isLoading,
  } = trpc.getLander.useQuery(
    {
      landerId: data.landerId,
    },
    {
      refetchOnMount: true,
    }
  );

  const { data: numberOfActiveAdsForBrand } =
    trpc.getNumberOfActiveAdsForBrand.useQuery(
      { brandId: data.brandId || "" },
      {
        enabled: open,
      }
    );

  const { data: authUser } = trpc.me.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {accessPromptDialogOpen && (
        <FreeAccessPrompt
          open={accessPromptDialogOpen}
          onOpenChange={() => setAccessPromptDialogOpen(false)}
        />
      )}

      {isLoading ? (
        <DrawerContent className={"h-[85vh] w-full"}>
          <div className="flex flex-col justify-center items-center w-full h-[85vh]">
            <Loader />
          </div>
        </DrawerContent>
      ) : (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <LanderInspirationDetailsMedia
              data={landerAdDetails ?? data}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
              IsPublic={IsPublic}
              numberOfActiveAdsForBrand={numberOfActiveAdsForBrand}
            />
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                <LanderInspirationDetailsBody
                  data={data}
                  setAccessPromptDialogOpen={setAccessPromptDialogOpen}
                  authUser={authUser}
                  permissions={permissions}
                />
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span onClick={onOpenChange} className={"p-1 cursor-pointer"}>
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <LanderInspirationDetailsBody
                data={data}
                setAccessPromptDialogOpen={setAccessPromptDialogOpen}
                authUser={authUser}
                permissions={permissions}
                mediaComponent={
                  <ScrollArea
                    className={
                      "bg-themeaccent rounded h-full w-full lg:min-h-[65vh]"
                    }
                  >
                    <LanderInspirationDetailsMedia
                      data={landerAdDetails ?? data}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                      IsPublic={IsPublic}
                      numberOfActiveAdsForBrand={numberOfActiveAdsForBrand}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      )}
    </Drawer>
  );
}
