import { Drawer, DrawerContent } from "@/components/shadcn/drawer.tsx";
import { AdDataProps } from "@/components/templates/AdCard";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { useState } from "react";
import { LandingAdDataProps } from "./templates/LandingAdCard";
import { ScrollArea } from "./shadcn/scroll-area";
import { trpc } from "@/utils/trpc";
import { TemplateDetailsAdMedia } from "./TemplateDetailsAdMedia";
import { TemplateDetailsAdBody } from "./TemplateDetailsAdBody";
import { TemplateDetailsLanderEmailMedia } from "./TemplateDetailsLanderEmailMedia";
import { TemplateDetailsLanderEmailBody } from "./TemplateDetailsLanderEmailBody";
import { X } from "@mynaui/icons-react";

export default function TemplateDetails({
  open,
  onCloseDrawer,
  data,
  landingData,
  type,
}: {
  open: boolean;
  onCloseDrawer: () => void;
  data?: AdDataProps;
  type?: "email" | "landing-page";
  landingData?: LandingAdDataProps;
}) {
  const [saveOpen, setSaveOpen] = useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const { data: numberOfUsers } = trpc.getAdTemplateDownloads.useQuery({
    adId: (data?.Ad["Creative ID"] ?? landingData?.ID ?? "").toString(),
  });
  const { data: numberOfSaves } = trpc.getAdTemplateSaves.useQuery({
    adId: `${data?.Ad["Creative ID"] ?? landingData?.ID ?? ""}`,
  });

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return `Posted ${date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}`;
  };

  return (
    <Drawer open={open} onOpenChange={onCloseDrawer}>
      {data && (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <TemplateDetailsAdMedia
              data={data}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
            />
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={"p-1 cursor-pointer"}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                <TemplateDetailsAdBody
                  dateSaved={formatDate(data.Ad.Created)}
                  data={data}
                  onCloseDrawer={onCloseDrawer}
                  saveOpen={saveOpen}
                  setSaveOpen={setSaveOpen}
                  copyToClipboard={copyToClipboard}
                  numberOfUsers={numberOfUsers ?? 0}
                  numberOfSaves={numberOfSaves ?? 0}
                />
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={"p-1 cursor-pointer"}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>
              <TemplateDetailsAdBody
                dateSaved={formatDate(data.Ad.Created)}
                data={data}
                onCloseDrawer={onCloseDrawer}
                saveOpen={saveOpen}
                setSaveOpen={setSaveOpen}
                copyToClipboard={copyToClipboard}
                numberOfUsers={numberOfUsers ?? 0}
                numberOfSaves={numberOfSaves ?? 0}
                mediaComponent={
                  <ScrollArea
                    className={
                      "bg-themeaccent rounded h-full w-full lg:min-h-[65vh]"
                    }
                  >
                    <TemplateDetailsAdMedia
                      data={data}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      )}
      {landingData && (
        <DrawerContent
          className={"h-[90vh] p-1 flex flex-col-reverse lg:flex-row w-full"}
          onInteractOutside={saveOpen ? (e) => e.preventDefault() : undefined}
        >
          <ScrollArea
            className={"bg-themeaccent rounded h-full w-full hidden lg:flex"}
          >
            <TemplateDetailsLanderEmailMedia
              type={type}
              landingData={landingData}
              saveOpen={saveOpen}
              setSaveOpen={setSaveOpen}
            />
          </ScrollArea>
          <div className="hidden lg:flex">
            <div className="flex flex-col rounded lg:h-full w-full lg:w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={"p-1 cursor-pointer"}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>

              <ScrollArea className="h-full">
                <TemplateDetailsLanderEmailBody
                  type={type}
                  landingData={landingData}
                  dateSaved={formatDate(landingData.created)}
                  onCloseDrawer={onCloseDrawer}
                  copyToClipboard={copyToClipboard}
                  numberOfUsers={numberOfUsers ?? 0}
                />
              </ScrollArea>
            </div>
          </div>
          <ScrollArea className="flex lg:hidden h-full">
            <div className="flex flex-col rounded lg:h-full w-full lg:max-w-[28rem]">
              <div className={"flex justify-end h-4 min-h-4"}>
                <span
                  onClick={() => onCloseDrawer()}
                  className={"p-1 cursor-pointer"}
                >
                  <X className="w-4 h-4 min-w-4" />
                </span>
              </div>
              <TemplateDetailsLanderEmailBody
                type={type}
                landingData={landingData}
                dateSaved={formatDate(landingData.created)}
                onCloseDrawer={onCloseDrawer}
                copyToClipboard={copyToClipboard}
                numberOfUsers={numberOfUsers ?? 0}
                mediaComponent={
                  <ScrollArea
                    className={
                      "bg-themeaccent rounded h-full w-full lg:min-h-[65vh]"
                    }
                  >
                    <TemplateDetailsLanderEmailMedia
                      type={type}
                      landingData={landingData}
                      saveOpen={saveOpen}
                      setSaveOpen={setSaveOpen}
                    />
                  </ScrollArea>
                }
              />
            </div>
          </ScrollArea>
        </DrawerContent>
      )}
    </Drawer>
  );
}
