import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Button } from '@/components/shadcn/button';
import { z } from 'zod';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { trpc } from '@/utils/trpc.ts';
import { ErrorDisplay } from '@/components/error';
import { Loader } from '@/components/custom-components/Loader';
import OnboardingComplete from '@/components/onboarding/OnboardingComplete.tsx';
import ClientUseCaseBrief from '@/components/onboarding/ClientUseCaseBrief.tsx';
import ClientLeadSource from '@/components/onboarding/ClientLeadSource.tsx';
import { ProtectedArea } from '@/components/protectedArea.tsx';
import OnboardingHeader from '@/components/onboarding/OnboardingHeader.tsx';
import { useEffect } from 'react';
import ClientInfo from '@/components/onboarding/ClientInfo';

type GetStartedProps = {
  step?: string;
};

export type UseCaseType =
  | 'find-ad-inspiration'
  | 'find-ad-creative-templates'
  | 'craft-high-performance-copy'
  | 'create-new-assets';

export type RoleType =
  | 'founder'
  | 'c-suite'
  | 'creative-strategist'
  | 'specialized-consultant';

export type CompanyType = 'agency' | 'brand' | 'freelance' | 'other';

export type LeadType =
  | 'social-media'
  | 'word-of-mouth'
  | 'email'
  | 'paid-search'
  | 'youtube'
  | 'other';

export const useCases = [
  { id: 1, label: 'Find ad inspiration', value: 'find-ad-inspiration' },
  {
    id: 2,
    label: 'Find ad creative templates',
    value: 'find-ad-creative-templates',
  },
  {
    id: 3,
    label: 'Craft high-performance copy',
    value: 'craft-high-performance-copy',
  },
  { id: 4, label: 'Create new assets', value: 'create-new-assets' },
];

export const roles = [
  { id: 1, label: 'Founder or Operator', value: 'founder' },
  { id: 2, label: 'C-suite or Director', value: 'c-suite' },
  { id: 3, label: 'Creative Strategist', value: 'creative-strategist' },
  { id: 4, label: 'Specialized Consultant', value: 'specialized-consultant' },
];

export const companyTypes = [
  { id: 1, label: 'Agency', value: 'agency' },
  { id: 2, label: 'Brand', value: 'brand' },
  { id: 3, label: 'Freelance or Consultant', value: 'freelance' },
  { id: 4, label: 'Other', value: 'other' },
];

export const leads = [
  { id: 1, label: 'Social Media', value: 'social-media' },
  { id: 2, label: 'Word of Mouth', value: 'word-of-mouth' },
  { id: 3, label: 'Email', value: 'email' },
  { id: 4, label: 'Paid Search', value: 'paid-search' },
  { id: 5, label: 'Youtube', value: 'youtube' },
  { id: 6, label: 'Other', value: 'other' },
];

export const Route = createFileRoute('/get-started')({
  component: GetStarted,
  validateSearch: (search: Record<string, unknown>): GetStartedProps => {
    return {
      step: search.step as string | undefined,
    };
  },
});

export const OnboardingFormSchema = z.object({
  use_cases: z
    .enum([
      'find-ad-inspiration',
      'find-ad-creative-templates',
      'craft-high-performance-copy',
      'create-new-assets',
    ])
    .optional(),
  roles: z
    .enum([
      'founder',
      'c-suite',
      'creative-strategist',
      'specialized-consultant',
    ])
    .optional(),
  company_types: z.enum(['agency', 'brand', 'freelance', 'other']).optional(),
  company_name: z.string().min(1, 'Company name is required'),
  leads: z
    .enum([
      'social-media',
      'word-of-mouth',
      'email',
      'paid-search',
      'youtube',
      'other',
    ])
    .optional(),
});

export type SectionProps = {
  form: UseFormReturn<z.infer<typeof OnboardingFormSchema>>;
};

function GetStarted() {
  const { step } = Route.useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate({ to: '/feeds/templates' }).then(() => {
      // remove the webflow selected plan once the user begins to onboard
      localStorage.removeItem('cos_plan');
      localStorage.removeItem('cos_coupon');
    });
  }, []);

  const {
    data: onboardCompletedData,
    isLoading,
    error,
  } = trpc.hasCompletedOnboarding.useQuery();

  const {
    data: onboardingResponsesData,
    isLoading: isLoadingOnboardingResponses,
    error: errorOnboardingResponses,
  } = trpc.getOnboardingSteps.useQuery();

  const form = useForm<z.infer<typeof OnboardingFormSchema>>({
    resolver: zodResolver(OnboardingFormSchema),
    defaultValues: {
      use_cases: undefined,
      roles: undefined,
      company_types: undefined,
      leads: undefined,
      company_name: undefined,
    },
  });

  useEffect(() => {
    form.reset({
      use_cases: onboardingResponsesData?.brief ?? undefined,
      roles: onboardingResponsesData?.role ?? undefined,
      company_types: onboardingResponsesData?.company ?? undefined,
      leads: onboardingResponsesData?.lead ?? undefined,
      company_name: onboardingResponsesData?.companyName ?? undefined,
    });
  }, [onboardingResponsesData, form]);

  // Users are redirected to login when not logged in
  if (error && error?.message === 'UNAUTHORIZED') {
    return (window.location.href = '/login');
  }

  if (error || errorOnboardingResponses) {
    return <ErrorDisplay />;
  }

  if (isLoading || isLoadingOnboardingResponses) {
    return (
      <div
        className={'flex justify-center items-center w-full h-screen m-auto'}
      >
        <Loader />
      </div>
    );
  }

  if (step) {
    if (step.toLowerCase() === 'ready') {
      return <OnboardingComplete />;
    }
    return (
      <ProtectedArea>
        <div
          className={
            'bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen'
          }
        >
          <div>
            <OnboardingHeader />
          </div>
          <div
            className={
              'flex-1 flex flex-col justify-center items-center py-[7.75rem]'
            }
          >
            {step.toLowerCase() === 'client-info' ? (
              <ClientInfo form={form} />
            ) : step.toLowerCase() === 'brief' ? (
              <ClientUseCaseBrief form={form} />
            ) : (
              step.toLowerCase() === 'lead' && <ClientLeadSource form={form} />
            )}
          </div>
        </div>
      </ProtectedArea>
    );
  }

  if (onboardCompletedData && !(isLoading || isLoadingOnboardingResponses)) {
    window.location.href = '/feeds/templates';
  }

  return (
    <ProtectedArea>
      <div
        className={'bg-brandgrad bg-no-repeat bg-center bg-cover min-h-screen'}
      >
        <div>
          <OnboardingHeader />
        </div>
        <div
          className={
            'flex-1 flex flex-col justify-center items-center py-[7.75rem]'
          }
        >
          <div
            className={
              'rounded-lg bg-white p-5 w-11/12 lg:w-[32rem] flex flex-col gap-6 border'
            }
          >
            <div className={'flex flex-col items-start gap-2'}>
              <h4 className={'text-4xl text-themeforeground font-semibold'}>
                Your creative process, <br />
                <span className={'italic'}>reimagined</span>
              </h4>
              <p className={'text-2xl'}>Let’s optimize your experience.</p>
            </div>

            <div>
              <Button
                onClick={() => {
                  navigate({
                    to: '/get-started',
                    search: { step: 'client-info' },
                  });
                }}
                type={'button'}
                className="w-full"
              >
                {'Let’s Do This'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ProtectedArea>
  );
}
