import React from "react";
import Masonry, {
  MasonryProps,
  ResponsiveMasonryProps,
  ResponsiveMasonry,
} from "react-responsive-masonry";

export const TypedResponsiveMasonry =
  ResponsiveMasonry as React.FC<ResponsiveMasonryProps>;

export const MasonryComponent = Masonry as React.FC<MasonryProps>;
